// import React, { Component } from 'react';

// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import { Form } from 'react-bootstrap';
// import Typography from '@material-ui/core/Typography';
// import Card from '@material-ui/core/Card';
// // import { Multiselect } from 'multiselect-react-dropdown';
// import MultiSelect from "react-multi-select-component";

// import SignatureCanvas from 'react-signature-canvas';

// import { REQUEST_URL_PREFIX } from '../../Utils'
// import _ from 'lodash';
// import Select from 'react-select';
// import { getClinicProducers } from "../../store/actions/producer";


// export default class UpdateVetDialog extends Component {

//     constructor(props) {

//       super(props);
//       this.state = {
//         open: null,
//         onClose: null,
//         vet_name: '',
//         clinic_name: '',
//         vet_id: '',
//         clinic_id: '',
//         signature: '',
//         clinic_options:[],
//         selectedObj:null,
//         producer_options : [],
//         vet_name_error : true,
//         vet_password_error : false,
//         clinic_name_error : false,
//         producers : []
//       }
//       this.handleChange = this.handleChange.bind(this);
//       this.handleClose = this.handleClose.bind(this);
//       this.handleChangeSelect = this.handleChangeSelect.bind(this);

//     };

//     // static contextType = this.props.authState;

//     handleChange(event) {
//       this.setState({
//           [event.target.name]: event.target.value
//       });
//     }

//     handleChangeSelect(event) {
//       this.setState({selectedObj: {
//         "value":  event.value,
//         "label": event.label,
//       },
//       clinic_id: event.value});
//       // this.fetchProducers(this.context.state.token, event.value)
//    }

//    showsignature=()=>{
//     this.sigPad.clear()
//     this.sigPad.fromDataURL(`data:image/png;base64,${this.props.signature}`)
//    }

  

//     clear = () => {
//         this.sigPad.clear()
//     }

//     componentDidMount() {

//         this.counter=0

//         this.intervalID = setInterval(() => {
//           if(this.counter===0) {
//             this.showsignature()
//           }
//           clearInterval(this.intervalID);
//         }, 1000);
//         this.setState({
//           open: this.props.open,
//           onClose: this.props.onClose,
//           vet_name: this.props.vet_name,
//           clinic_name: this.props.clinic_name,
//           vet_id: this.props.id,
//           clinic_id: this.props.clinic_id,
//           signature: this.props.signature,
//           selectedObj: {"value":  this.props.clinic_id,  "label": this.props.clinic_name},
//           producers : selectedProducers
//         }
//     )

//     };

//     onSelect = (selectedList, selectedItem) => {
//       this.setState({producers : selectedList})
//     }

//     handleClose(event) {

//       console.log("EditVetDialog handleClose")

//       // event.preventDefault();
//       const save = event.target.textContent==="Save"?true:false;
//       // save modification
//       if (save) {
//         const bodyContent = {
//           clinic_id: this.state.clinic_id,
//           vetId: this.state.vet_id,
//           name: this.state.vet_name,
//           producers : this.state.producers.map(p => parseInt(p.value)),
//           signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png')
//         }
//         new Promise(() => {
//           fetch(REQUEST_URL_PREFIX + 'updatevet', {
//               method: 'POST',
//               headers: {
//                 "Content-type": "application/json; charset=UTF-8",
//                 "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
//                 "Authorization":this.state.authState.token
//               },
//               body: JSON.stringify(bodyContent),
//             }).then(response => {
//               if (response.status === 200) {
//                 console.log("update vet successfully")
//               } else {
//                 console.log("update vet failed")
//               }
//               return response.json()
//             }).then((content) => {
//               this.state.onClose(bodyContent);
//             }).catch((error)=>{
//                 console.log(error);
//             })
//         })
//       }else{
//         this.state.onClose(null);
//       }

//     };

//     render() {


//         return (
//           <Dialog aria-labelledby="simple-dialog-title" open={this.props.open}>
//             <DialogTitle id="simple-dialog-title">{this.props.vet_name}</DialogTitle>
//             <div className="managevet-popup">
//             <Form noValidate autoComplete="off">
//                     <div>
//                     <TextField id="vet_name" label="Vet Name" variant="outlined" id="vet_name" name="vet_name" defaultValue={this.props.vet_name} value={this.state.vet_name} onChange={this.handleChange} />
//                     </div>
//                     <label for="Select Clinic...">Select Clinic...</label>
//                     <Select
//                             value={this.state.selectedObj}
//                             onChange={this.handleChangeSelect}
//                             options={this.state.clinic_options}
//                     />
//                       <br/>
//                       <label for="select producers..">Select Producers</label>
//                       <MultiSelect
//                           options={this.state.producer_options}
//                           value={this.state.producers}
//                           onChange={this.onSelect}
//                           labelledBy="Select Producers"
//                           />
//                           <br/>
//                           <p style={{color:"red"}}><b>Note</b>: If a Vet has signed and submitted an evaluation of a Producer that was assigned to him, vet name will still remain on that evaluation. Any new Producer assigned or removed will only impact the new evaluations.</p>
//                       <br/>
//                     <Typography variant="overline" gutterBottom><h6>Sign Below</h6></Typography>
//                     <Card>
//                         <SignatureCanvas  canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />

//                     </Card>
//                     <div className="clear-sig">
//                        <a onClick={this.clear}><u>Clear Signature</u></a>
//                     </div>

//                 <Button variant="contained" color="primary" name="save" id="save" onClick={this.handleClose}>Save</Button>
//                 <Button variant="contained" color="secondary" name="cancel" id="cancel" onClick={this.handleClose}>Cancel</Button>
//               </Form>
//             </div>
//           </Dialog>
//         );
//       }
//     }

import React, { Component, useState, useEffect } from 'react';

import MaterialTable from 'material-table'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Form } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
// import { Multiselect } from 'multiselect-react-dropdown';
import MultiSelect from "react-multi-select-component";

import SignatureCanvas from 'react-signature-canvas';

import { REQUEST_URL_PREFIX } from '../../Utils'
// import { AuthContext } from "../App";
import _ from 'lodash';
import Select from 'react-select';
import { getClinicProducers } from "../../store/actions/producer";
export default class EditVetDialog extends Component {

    constructor(props) {

      super(props);
      this.state = {
        open: null,
        onClose: null,
        vet_name: '',
        clinic_name: '',
        vet_id: '',
        clinic_id: '',
        signature: '',
        clinic_options:[],
        selectedObj:null,
        producer_options : [],
        vet_name_error : true,
        vet_password_error : false,
        clinic_name_error : false,
        producers : [],
        authState: {}
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleChangeSelect = this.handleChangeSelect.bind(this);

    };

    // static contextType = AuthContext;

    handleChange(event) {
      this.setState({
          [event.target.name]: event.target.value
      });
    }

    handleChangeSelect(event) {
      this.setState({selectedObj: {
        "value":  event.value,
        "label": event.label,
      },
      clinic_id: event.value});
      this.fetchProducers(this.context.state.token, event.value)
   }

   showsignature=()=>{
    this.sigPad.clear()
    this.sigPad.fromDataURL(`data:image/png;base64,${this.props.signature}`)
    console.log('signature')
   }

   fetchProducers = (token, cid) => {
    let prods = []
    getClinicProducers(token, cid).then(({data})=> {
      prods = data.producercList.map(d => {
        return {
          'label' : d.name,
          'value': d.producer_id
        }
      })
      this.setState({
        producer_options : prods
      })
      // this.setState({
      //   producers : prods
      // })
    }).catch(err => {
      console.log('err', err)
    })
  }

    clear = () => {
        this.sigPad.clear()
    }

    componentDidMount() {

        this.counter=0
        console.log('current props', this.props)
        this.intervalID = setInterval(() => {
          if(this.counter===0) {
            this.showsignature()
          }
          clearInterval(this.intervalID);
        }, 1000);
          let selectedProducers = this.props.selectedProducers;
          if(selectedProducers.length > 0) {
            selectedProducers = selectedProducers.map(d => {
              return {
                'label' : d.name,
                'value': d.producer_id
              }
            })
          }
        this.setState({
          open: this.props.open,
          onClose: this.props.onClose,
          vet_name: this.props.vet_name,
          clinic_name: this.props.clinic_name,
          vet_id: this.props.id,
          clinic_id: this.props.clinic_id,
          signature: this.props.signature,
          selectedObj: {"value":  this.props.clinic_id,  "label": this.props.clinic_name},
          producers : selectedProducers,
          authState : this.props.authState
      }
    )
      let clinicoptions =[];
      // fetch(REQUEST_URL_PREFIX + 'cliniclist', {
      //   method: 'GET',
      //   headers: {
      //     "Content-type": "application/json; charset=UTF-8",
      //     "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
      //     "Authorization": this.context.state.token
      //   }
      // }).then(response => {
      //   if (response.status === 200) {
      //   } else {
      //     console.log("get failed")
      //   }
      //   return response.json();
      // }
      // ).then(result=>{
      // //  clinicoptions=[];
      //   for (let i = 0; i < result.clinicList.length; i++) {
      //     if ( result.clinicList[i].clinicId) {
      //     clinicoptions.push({
      //         "value":  result.clinicList[i].clinicId,
      //         "label": result.clinicList[i].clinic_name
      //       });
      //     }
      //   }
      //   if(this.context.state.isClinic) {
      //     let clinicId = this.context.state.user.clinicId;
      //     let temp = _.cloneDeep(clinicoptions);
      //     let ClinicFiltered = temp.filter(t => t.value == clinicId)
      //     clinicoptions = _.cloneDeep(ClinicFiltered)
      //     this.fetchProducers(this.context.state.token, clinicId)

      //   }

      //   this.setState({ clinic_options : clinicoptions })
      // })
      // .catch((error)=>{
      //     console.log(error);
      // })

    };

    onSelect = (selectedList, selectedItem) => {
      this.setState({producers : selectedList})
    }

    handleClose(event) {

      console.log("EditVetDialog handleClose")

      // event.preventDefault();
      const save = event.target.textContent==="Save"?true:false;
      // save modification
      if (save) {
        const bodyContent = {
          name: this.state.vet_name,
          signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png')
        }
        new Promise(() => {
          fetch(REQUEST_URL_PREFIX + 'vet/updateself', {
              method: 'POST',
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                "Authorization": this.state.authState.token
              },
              body: JSON.stringify(bodyContent),
            }).then(response => {
              if (response.status === 200) {
                console.log("update vet successfully")
              } else {
                console.log("update vet failed")
              }
              return response.json()
            }).then((content) => {
              this.state.onClose(bodyContent);
            }).catch((error)=>{
                console.log(error);
            })
        })
      }else{
        this.state.onClose(null);
      }

    };

    render() {


        return (
          <Dialog aria-labelledby="simple-dialog-title" open={this.props.open}>
            <DialogTitle id="simple-dialog-title">{this.props.vet_name}</DialogTitle>
            <div className="managevet-popup">
            <Form noValidate autoComplete="off">
                    <div>
                    <TextField id="vet_name" label="Vet Name" variant="outlined" id="vet_name" name="vet_name" defaultValue={this.props.vet_name} value={this.state.vet_name} onChange={this.handleChange} />
                    </div>
                    <Typography variant="overline" gutterBottom><h6>Sign Below</h6></Typography>
                    <Card>
                        <SignatureCanvas  canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
   
                    </Card>
                    <div className="clear-sig">
                       <a onClick={this.clear}><u>Clear Signature</u></a>
                    </div>

                <Button variant="contained" color="primary" name="save" id="save" onClick={this.handleClose}>Save</Button>
                <Button variant="contained" color="secondary" name="cancel" id="cancel" onClick={this.handleClose}>Cancel</Button>
              </Form>
            </div>
          </Dialog>
        );
      }
    }