import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

class Sidebar extends Component {
  state = {};
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/clinic', state: 'clinicMenuOpen'},
      {path:'/tools', state: 'toolsMenuOpen'},
      {path:'/reports', state: 'reportsMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
    console.log(this.props.isAdmin);

  }
  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html"><img src="/images/logo.png" alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src="/images/logo-mini.png" alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="brand_menu_container" style={{cursor:'pointer'}}>
            <div className={'nav-link'} onClick={ () => this.props.history.push("/") } >
              <center >
                <img className="normal-logo"  src='/images/admin_logo.png' alt="WCABP" title="WCABP" />
                <img className="mini-logo"  src='/images/admin_icon.png' alt="WCABP" title="WCABP" />
              </center>
            </div>
          </li>
          <li className={ this.isPathActive('/clinic') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.clinicMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('clinicMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Clinic</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.clinicMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">{
                this.props.isAdmin?<Link className={ this.isPathActive('/clinic/admin-clinic-list') ? 'nav-link active' : 'nav-link' } to="/clinic/admin-clinic-list"> Clinic List </Link>
                :<Link className={ this.isPathActive('/clinic/clinic-producers-list') ? 'nav-link active' : 'nav-link' } to="/clinic/clinic-producers-list"> Producers </Link>
                } </li>
                <li className="nav-item">{this.props.isAdmin?<Link className={ this.isPathActive('/clinic/admin-new-clinic-request') ? 'nav-link active' : 'nav-link' } to="/clinic/admin-new-clinic-request"> New Clinic Requests </Link>
                :''} </li>
                <li className="nav-item">{this.props.isAdmin?<Link className={ this.isPathActive('/clinic/admin-add-new-clinic') ? 'nav-link active' : 'nav-link' } to="/clinic/admin-add-new-clinic"> Add New Clinic </Link>
                :''} </li>
              </ul>
            </Collapse>
          </li>

          <li className={ this.isPathActive('/tools') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.toolsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('toolsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-tools menu-icon"></i>
              <span className="menu-title">Tools</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.toolsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item" style={{ display: (JSON.parse(localStorage.getItem('isAdmin')) ? 'block' : 'none')}}>
                  <Link className='nav-link' to="/Adminorders">Orders</Link>
                </li>
                <li className="nav-item">{
                  this.props.isAdmin?<Link className={ this.isPathActive('/tools/info-prac-prod') ? 'nav-link active' : 'nav-link' } to="/tools/info-prac-prod">Information for Practitioners and Producers</Link>:
                  <Link className={ this.isPathActive('/tools/info-prac-prod-clinic') ? 'nav-link active' : 'nav-link' } to="/tools/info-prac-prod-clinic">Information for Practitioners and Producers</Link>
                } </li>
                <li className="nav-item">{
                  this.props.isAdmin?<Link className={ this.isPathActive('/tools/manage-vets') ? 'nav-link active' : 'nav-link' } to="/tools/manage-vets">Manage Veterinarians</Link>:
                  <Link className={ this.isPathActive('/tools/manage-vets-clinic') ? 'nav-link active' : 'nav-link' } to="/tools/manage-vets-clinic">Manage Veterinarians</Link>
                } </li>
                <li className="nav-item">
                <Link className='nav-link' to="/taxes">Manage Taxes</Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li className={ this.isPathActive('/reports') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.reportsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('reportsMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-file-search-outline menu-icon"></i>
              <span className="menu-title">Reports</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.reportsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">{this.props.isAdmin? <Link className={ this.isPathActive('/reports/search-reports') ? 'nav-link active' : 'nav-link' } to="/reports/search-reports">Search Reports</Link>
                :<Link className={ this.isPathActive('/reports/search-reports-clinic') ? 'nav-link active' : 'nav-link' } to="/reports/search-reports-clinic">Search Reports</Link>
              } </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);
