import React, { Component,Suspense, lazy, useContext } from 'react';
import { Switch, Route, useHistory} from 'react-router-dom';
import { AuthContext } from "./App";
import { checkToken } from './store/actions/auth';
import Spinner from '../app/shared/Spinner';
import AdminOrders from './pages/Adminorder';
import SingleOrder from './pages/Adminorder/SingleOrder';


const Clinic_producersList = lazy(() => import('./clinic/Clinic_producers'));
const AdminClinicList = lazy(() => import('./clinic/AdminClinicList'));
const AdminAddNewClinic = lazy(() => import('./clinic/AdminAddNewClinic'));
const AdminNewClinicRequest = lazy(() => import('./clinic/AdminNewClinicRequest'));
const AdminClinicReqViewDetails = lazy(() => import('./clinic/ClinicRequestViewDetails'));
const ClinicListDetails = lazy(() => import('./clinic/ClinicListDetails'));
const ProducerDetails = lazy(() => import('./clinic/ProducerDetails'));
const EditEvaluation = lazy(() => import('./clinic/EditEvaluation'));
const Evaluations = lazy(() => import('./clinic/Evaluations'));
const AddNewEvaluation = lazy(() => import('./clinic/AddNewEvaluation'));
const CreateNewProducer = lazy(() => import('./clinic/CreateNewProducer'));
const CreateNewBull = lazy(() => import('./clinic/CreateNewBull'));
const AdminInfoPracProd = lazy(() => import('./tools/InfoPracProd'));
const AdminManageVets = lazy(() => import('./tools/ManageVets'));
const ClinicInfoPracProd = lazy(() => import('./tools/InfoPracProd_Clinic'));
const ClinicManageVets = lazy(() => import('./tools/ManageVets_Clinic'));
const AdminSearchReports = lazy(() => import('./reports/SearchReports'));
const ClinicSearchReports = lazy(() => import('./reports/ClinicSearchReports'));
const AdminSearchResult = lazy(() => import('./reports/SearchResult'));
const Taxes = lazy(() => import('./tools/taxes'));
const EditBull  = lazy(() =>  import('../app/pages/bull/EditBull'));



const AppRoutes = (props) => {
  const history = useHistory()
  const context = useContext(AuthContext)
  React.useEffect(() => {
    checkToken(context.state.token).then().catch(err => {
      if(err.response.status === 401){
        context.dispatch({type: "LOGOUT"})
        history.push("/")
      }
    })
  }, [])
 
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
        <Route exact path="/" component={ props.isAdmin?AdminClinicList:Clinic_producersList } />
          <Route exact path="/clinic/admin-clinic-list" component={ AdminClinicList } />
          <Route exact path="/clinic/admin-add-new-clinic" component={ AdminAddNewClinic } />
          <Route exact path="/clinic/admin-new-clinic-request" component={ AdminNewClinicRequest } />
          <Route exact path="/clinic/clinic-request-view-details" component={ AdminClinicReqViewDetails } />
          <Route exact path="/clinic/admin-clinic-list-details" component={ ClinicListDetails } />
          <Route exact path="/clinic/admin-clinic-producer-details" component={ ProducerDetails } />
          <Route exact path="/clinic/admin-bull-evaluations" component={ Evaluations} />
          <Route exact path="/clinic/add-new-evaluation" component={ AddNewEvaluation} />
          <Route exact path="/clinic/create-new-producer" component={ CreateNewProducer} />
          <Route exact path="/clinic/create-new-bull" component={ CreateNewBull} />
          <Route exact path="/clinic/edit-evaluation" component={ EditEvaluation} />
          <Route exact path="/tools/info-prac-prod" component={ AdminInfoPracProd } />
          <Route exact path="/tools/info-prac-prod-clinic" component={ ClinicInfoPracProd } />
          
          <Route exact path="/tools/manage-vets" component={ AdminManageVets } />
          <Route exact path="/tools/manage-vets-clinic" component={ ClinicManageVets } />
          <Route exact path="/reports/search-reports" component={ AdminSearchReports } />
          <Route exact path="/reports/search-reports-clinic" component={ ClinicSearchReports } />
          <Route exact path="/reports/search-result" component={ AdminSearchResult } />
          <Route exact path="/clinic/clinic-producers-list" component={ Clinic_producersList } />
          <Route exact path="/clinic/clinic-bulls-list" component={ AdminClinicList } />
          <Route exact path="/clinic/clinic-bulls-evaluations" component={ AdminClinicList } />
          <Route exact path="/taxes" component={ Taxes } />
          <Route exact path="/Adminorders" component={AdminOrders} />
          <Route exact path="/Adminorder/:id" component={SingleOrder} />
          <Route exact path="/clinic/:producer_id/bull/:id" component={EditBull} />
          
          
        </Switch>
      </Suspense>
    );
}

export default AppRoutes;
