import React, {useState, useRef, useContext} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withSnackbar} from 'notistack';
import {sendOTP} from './service';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/common/Loading';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: '100%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, isLoading, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {(onClose && !isLoading) ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = withStyles({
    table: {
        minWidth: 650,
      },
});

const StatusModal = ({resources, open, hadleOTPModal, refetch, isLoading}) => {
    // const classes = useStyles();
  
  return (
    <Dialog  onClose={() => {!isLoading && hadleOTPModal(false)}} 
    fullWidth={true}
    maxWidth="sm"
     aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" isLoading={isLoading} onClose={() => hadleOTPModal(false)}>
        <center><strong>Resource Status</strong></center>
        <center><Button variant="contained" color="primary" onClick={refetch}>Refetch</Button></center>
        <center><strong><small style={{color:"red"}}>**Please make sure to check that all resources are fetched before closing the pop up, if some are marked as failed, please check internet connection and refetch.</small></strong></center>
      </DialogTitle>
      <DialogContent>
      {isLoading ? <Loading /> : <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Resource</TableCell>
                    <TableCell align="right">State</TableCell>
                    {/* <TableCell align="right">Action</TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody>
            {resources.map((row, i) => (
                <TableRow key={i}>
                <TableCell component="th" scope="row">
                    {row.resource}
                </TableCell>
                <TableCell align="right">{row.state}</TableCell>
                {/* <TableCell align="right">resources</TableCell> */}
                </TableRow>
            ))}
            </TableBody>
        </Table>
      </TableContainer>}
      </DialogContent>
    </Dialog>
  )
}
export default withSnackbar(StatusModal);
