import axios from './../axios'
import key from './key'

export const createBull = async (token, bull) =>{
  return await axios.post('/newbull', bull, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getBull = async (id, token) =>{
  return await axios.get(`/bulldetails/${id}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const updateSingleBull = async (bull, token) =>{
  return await axios.post(`/updatebull`, bull, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const deleteBullAction = async (token, bull_id) =>{
  return await axios.post('/deletebull', {bull_id: bull_id}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getBullsTreatment = async (token, bull_id) => {
  return await axios.get(`/bull/treatments/${bull_id}`, {
    headers: {
      "x-api-key": key,
      "Authorization": token
    }
  })
}