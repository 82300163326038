import React, {useRef,useState, useContext,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import MuiPhoneNumber from "material-ui-phone-number";
import {Button, makeStyles, Grid,FormControl,Select} from '@material-ui/core'
import { getAllProvinces, getclinicorder} from '../../store/actions/clinic'
import {getSingleProducer, updateProducer, getProducerBullDetails} from '../../store/actions/producer'
import {withSnackbar} from 'notistack'
import { AuthContext } from "../../App"
import { OrderContext } from "../../App"
import {Form} from 'react-bootstrap'
import { InputBase, IconButton  } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import BullItem from '../../components/Bull/BullItem';
import Loading from '../../components/common/Loading';
import { getallProvinces, getProducerDetails,
  getBullDataWithProducerId, getClinicordercount,
  updateProducerDetails, getVetProducerdetails } from '../../indexedDB';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(4),
    width: '20ch',
  },
}));

const EditProducer = (props) => {
  let context = useContext(AuthContext)
  const isValidZip =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  let history = useHistory()
  let {state} = useContext(AuthContext)

  const [producerBullsDetail, setProducerBullsDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [tempProducerBullsDetail, setTempProducerBullsDetail] = useState([])
  const [searchText, setSearchText] = useState("")
  const [provinces, setProvinces] = useState([])
  const [orderCount, setOrderCount] = useState(OrderContext.order_count)
  const [producer, setProducer] = useState({
    name:"",
    clinic_id:"",
    fax:"",
    phone:"",
    postal_code:"",
    email:"",
    province_id:"",
    city:"",
    address:""
  })
  const formRef = useRef(null);
  const classes = useStyles();
  function urlList() {

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Producers </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/offline">
                  { state.isOnline && <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />}
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Edit Producer offline</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name=='postal_code') {
  const formattedPostalCode = event.target.value.replace(/[^0-9a-zA-Z]/gi, "").toUpperCase();
  if (formattedPostalCode.length > 3) {
    value = formattedPostalCode.slice(0, 3) + " " + formattedPostalCode.slice(3);
  } else {
    value = formattedPostalCode;
  }
}



    setProducer(prevForm => ({
      ...prevForm,
      [name]:value
    }))
  }
  useEffect(() => {
    setLoading(true)
    getClinicordercount().then(({data}) => {
      if(data){
        setOrderCount(data.count)
      }
      setLoading(false)
    }).catch(err => {
      console.log(err)
    })
  },[]);
  useEffect(() => {
    setLoading(true)
    getallProvinces().then(({data}) => {
      setProvinces(data.provinceList)
      setLoading(false)
    }).catch(err => {
      console.log("err", err)
    })
    let id = props.match.params.id
    setLoading(true)
    console.log(state);
    getProducerDetails(id).then(({data}) => {
      setProducer(data.producerDetail)
      setLoading(false)
    }).catch(err => {
      console.log("err", err)
    })
    setLoading(true)
    getBullDataWithProducerId(id).then(({data}) => {
      setLoading(false)
      console.log("data.producerDetail.bull_list",data.bull_list)
      setProducerBullsDetail(data.bull_list)
      setTempProducerBullsDetail(data.bull_list)
    }).catch(err => {
      console.log('err', err)
    })
  }, [])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidZipcode', (value) => {
      if (isValidZip.test(value)!==true) {
          return false;
      }
      return true;
    });
  }, [])


  const onHandleSubmit = () => {
    producer.producer_Id = producer.ID || producer.producer_id;
    updateProducerDetails(producer.producer_Id, producer, state.user.userId).then(()  => {
      history.push("/offline")
      props.enqueueSnackbar('Updated Producer Successfully',{variant:"success"});
    }).catch(err => {
      console.log("err", err)
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    })
  }

  const search_bulls = (event) => {
    setSearchText(event.target.value)
    let value = event.target.value
    let searchValues = producerBullsDetail.filter(bull => bull.rfid_tag.toLowerCase().includes(value.toLowerCase()))
    setTempProducerBullsDetail(searchValues)
  }
  if(loading){
    return <Loading />
  }
  return (
    <>
      {urlList()}
      <OrderContext.Consumer>
        {
          ({order_count, set_order_count}) => {
            set_order_count(orderCount)
            return (
              <>
              <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
                <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
                  <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Edit Producer</strong></span>
                </div>
                <div className="add-new-bull">
                  <ValidatorForm ref={formRef} onSubmit={onHandleSubmit} className={classes.root} noValidate autoComplete="off">
                    <div className={classes.root} style={{padding:'20px'}}>
                      <Form>
                        <Grid container spacing={1}>
                          <Grid item xs sm={3}>
                            <TextValidator
                              className="addProdColThree"
                              required id="name"
                              label="Name"
                              name="name"
                              value={producer.name}
                              onChange={(event) => onChangeHandler(event)}
                              variant="outlined"
                              validators={['required', 'maxStringLength:32']}
                              errorMessages={['this field is required', 'the max length is 32']}
                            />
                          </Grid>
                          <Grid item xs sm={3}>
                            <TextValidator
                              className="addProdColThree"
                              required id="email"
                              label="Email"
                              name="email"
                              value={producer.email}
                              onChange={(event) => onChangeHandler(event)}
                              variant="outlined"
                              validators={['required', 'isEmail']}
                              errorMessages={['this field is required', 'email address is not valid']}
                            />
                          </Grid>
                          <Grid item xs sm={3}>
                            <MuiPhoneNumber className="addProdColThree" required
                              onlyCountries	={['ca','us']}
                              name="phone"
                              onChange={(event) => setProducer({...producer, phone:event})}
                              label="Phone"
                              value={producer.phone}
                              variant="outlined"
                              data-cy="user-phone"
                              defaultCountry={"ca"}
                            />
                            {
                              producer.phone == '+' && <p style={{color:'red',marginTop:'-18px', marginLeft:'13px'}} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required" id="postcal-code-helper-text">Phone Cannot be empty</p>
                            }
                          </Grid>

                          <Grid item xs sm={3}>
                            <MuiPhoneNumber
                              className="addProdColTwo"
                              onlyCountries	={['ca','us']}
                              disableDropdown="true"
                              name="fax"
                              value={producer.fax}
                              label="Fax"
                              onChange={(event) => setProducer({...producer, fax:event})}
                              variant="outlined"
                              data-cy="user-phone"
                              defaultCountry={"ca"}
                            />


                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs sm={3}>
                            <TextValidator
                              className="addProdColThree"
                              required id="streetaddr"
                              label="Location"
                              variant="outlined"
                              name="address"
                              value={producer.address}
                              onChange={(event) => onChangeHandler(event)}
                              validators={['required', 'maxStringLength:100']}
                              errorMessages={['this field is required', 'the max length is 100']}
                            />
                          </Grid>

                          <Grid item xs sm={3}>
                            <TextValidator
                              className="addProdColThree"
                              required id="city"
                              label="City"
                              name="city"
                              value={producer.city}
                              onChange={(event) => onChangeHandler(event)}
                              variant="outlined"
                              validators={['required', 'maxStringLength:32']}
                              errorMessages={['this field is required', 'the max length is 32']}
                            />
                          </Grid>

                          <Grid  item xs sm={3}>
                          <div className="selecterwrap">

                            <FormControl variant="outlined"  className={classes.formControl}>
                              <Select
                                native
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                variant="outlined"
                                label="Province"
                                name="province"
                                value={producer.province}
                                onChange={(event)=>onChangeHandler(event)}
                              >

                                {provinces.map(province => (
                                  <option value={province.province_name} key={province.provinceId}>{province.province_name}</option>
                                ))}
                              </Select>
                            </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs sm={3}>
                            <TextValidator
                              className="addProdColTwo"
                              id="postcal-code"
                              onChange={(event) => onChangeHandler(event)}
                              label="Postal Code"
                              name="postal_code"
                              value={producer.postal_code}
                              required variant="outlined"
                              validators={['required','isValidZipcode']}
                              errorMessages={['this field is required', 'This Postal code is not valid']}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs sm={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              // onClick={onHandleSubmit}
                            >
                              Update
                            </Button>
                          </Grid>
                          <Grid item xs sm={1}>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              onClick={() => props.history.push('/offline')}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>

                    </div>
                  </ValidatorForm>
                </div>

              </div>
              {/* <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
              <div className="MuiToolbar-root MuiToolbar-regular mb-4 producer_search edit_search" style={{padding:15, }}>
                  <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>All Bulls</strong></span>
                    <InputBase
                      className={classes.input}
                      placeholder="Search Bulls"
                      value={searchText}
                      name="serch_text"
                      onChange={search_bulls}
                    />
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <Button style={{float:'right'}}variant="contained" color="primary" onClick={() => props.history.push('/offline/add-bull', {
                      producerId: producer.ID,
                      producer:producer,
                      clinic_id:producer.clinic_id
                      } )}>
                      Add Bull
                    </Button>
                </div>

              </div> */}
              <BullItem bulls={tempProducerBullsDetail} producer={producer} producer_id={props.match.params.id} />
              </>
            )
          }
        }
      </OrderContext.Consumer>




    </>


  );
}

export default withSnackbar(EditProducer)
