import React,  {useContext, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {useHistory} from 'react-router-dom'
import {IconButton} from '@material-ui/core'
import DeleteBull from './DeleteBull';
import {deleteBullAction} from '../../store/actions/bull'
import {AuthContext} from '../../App'
import { useSnackbar } from 'notistack';
import {Link} from 'react-router-dom'
import { REQUEST_URL_PREFIX } from '../../Utils'
import {getclinicorder} from '../../store/actions/clinic'
import Dialog from '@material-ui/core/Dialog'
// import {Link} from 'react-router-dom'
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const BullItemClinic = (props) => {
    const { state: authState } = React.useContext(AuthContext);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // let {state} = useContext(AuthContext);
    const {isOnline} = authState;
    let [data, setData] = useState([])
    let [total_count, setTotalCount] = useState(0)
    let {state} = useContext(AuthContext)
    const [bull_id, setBullId] = useState('')
    const [bull_name, setBullName] = useState('')
    const [openConfirmBull, setOpenConfirmBull] = useState(false);
    const [open, setOpen] = React.useState(false)
    const [abnormality_list, setAbnormlityList] = useState(null);
    const [treatment_list, setTreatmentList] = useState(null);
    const [vet_list, setVetList] = useState(null);
    const [breed_list, setBreedList] = useState(null);
    const [producer_name, setProducerName] = useState();
    const [producer, setProducer] = useState();
    const producer_id = '';
    const clinic_name = '';
    const [provinceList, setProvinceList] = useState([]);
    const [prefixURL, setPrefixURL] = useState('');
    let history = useHistory()
    const [bull, setBull] = React.useState(null)
    const [bulls, setBulls] = React.useState([])
    let color, backgroundColor,bordercolor;
    if(props.isPublished){
      backgroundColor="#def5db"
      color="#6eb542"
      bordercolor="#def5db"
    }else{
      backgroundColor="#fae0c9"
      color="#f68c31"
      bordercolor="#fae0c9"
    }
    React.useEffect(() => {
      setBulls(props.bulls)
    }, [props.bulls]);

    const handleDialog = (bull) => {
      setBull(bull)
      handleBullDialog(true)
    }

    const handleBullDialog = (value) => {
      setOpen(value)
    }

    const deleteSubmit = () => {
      deleteBullAction(state.token, bulls.bull_id).then((data) => {
        enqueueSnackbar(`Bull with id ${bulls.bull_id} deleted successfully`,{variant:'success'});
        setOpen(false)
        const newBulls = bulls.filter((item) => item.bull_id !== bulls.bull_id);
        setBulls(newBulls);
      }).catch(err => {
        console.log('err', err)
        enqueueSnackbar('something wrong while deleting bull',{variant:'warning'});
      })
    }

    useEffect(() => {
      if(!isOnline){
        setPrefixURL("/offline");
      }
      new Promise((resolve)=>{
        fetch(REQUEST_URL_PREFIX + 'provincelist', {
              method: 'GET',
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                "Authorization": state.token
              }
            }).then(response => {
              if (response.status === 200) {
               } else {
                 console.log("get failed")
               }
               return response.json();
            }
            ).then(result=>{
              setProvinceList(result.provinceList)
            }).catch((error)=>{
                console.log(error);
            })
        })


    new Promise(() => {
      fetch(REQUEST_URL_PREFIX + 'breedlist', {
          method: 'GET',
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
            "Authorization": state.token
          },
        }).then(response => {
          // console.log(response);
          if (response.status === 200) {
            console.log("get successfully")
          } else {
            console.log("get failed")
          }
          return response.json()
        }).then((content) => {
          setBreedList(content.breedList)
        }).catch((error)=>{
            console.log(error);
        })
    })

      new Promise(() => {
          fetch(REQUEST_URL_PREFIX + 'abnormalitylist', {
              method: 'GET',
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                "Authorization": state.token
              },
            }).then(response => {
              console.log(response);
              if (response.status === 200) {
                console.log("get successfully")
              } else {
                console.log("get failed")
              }
              return response.json()
            }).then((content) => {
              // console.log(content);
              setAbnormlityList(content.abnormalityList)
            })
              .catch((error)=>{
                console.log(error);
            })
        })

        new Promise(() => {
          fetch(REQUEST_URL_PREFIX + 'treatmentlist', {
              method: 'GET',
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                "Authorization": state.token
              },
            }).then(response => {
              // console.log(response);
              if (response.status === 200) {
                console.log("get successfully")
              } else {
                console.log("get failed")
              }
              return response.json()
            }).then((content) => {
              // console.log(content);
              setTreatmentList(content.treatmentList)
            })
              .catch((error)=>{
                console.log(error);
            })
        })
        setProducer(props.producer)

      },[props.producer])

    // const AddNewEvaluation  = (row) => {
    //   getclinicorder(state.token, producer.clinic_id).then(({data}) => {
    //     if(data.count > 0) {
    //       history.push('/clinic/add-new-evaluation',
    //         {
    //           clinicName: '',
    //           clinic_id: producer.clinic_id,
    //           producerName: producer,
    //           clinicId: producer.clinic_id,
    //           producerId: producer_id,
    //           bullId: row.bull_id,
    //           tag: row.rfid_tag,
    //           tag_type:row.tag_type,
    //           name: row.name,
    //           brandinput: row.brand,
    //           birthDate: new Date(row.birth_date*1000).toISOString().slice(0,10),
    //           breed: row.breed_id,
    //           breedothers: row.breed_other,
    //           producerName:props.producer.name
    //         })
    //     } else {
    //       let msg;
    //       if(state.isClinic) {
    //         msg = 'Not enough credits, please buy some to create new evaluation';
    //       } else {
    //         msg = 'Not enough credits, please ask the clinic owner to buy some more credits to create new evaluations';
    //       }
    //       enqueueSnackbar(`${msg}`,{variant:'warning'});
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //     enqueueSnackbar('something wrong while, please reload the page',{variant:'warning'});
    //   })
    // }
    const fetchData = async() => await fetch(REQUEST_URL_PREFIX + 'producerbasicwithbullinfo/' + producer_id, {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
        "Authorization": authState.token
      }
    }).then(response => {
      if (response.status === 200) {
       } else {
         console.log("get failed")
       }
       return response.json();
    }
    ).then(result=>{
        console.log(result)
      if (result.producerDetail.bull_list) {
        console.log('result.producerDetail.bull_list',result.producerDetail.bull_list)
        setData(result.producerDetail.bull_list)
        setTotalCount(result.producerDetail.bull_list.length)
      }
      else {setData([])}
    }).catch((error)=>{
        console.log(error);
    })
    const editBull = (event, rowData) => {
      debugger;
      history.push(`/clinic/${props.producer.ID}/bull/${rowData.bull_id}`, {
        clinic_id:props.producer.clinic_id,
        producer:props.producer
      })
    }

    const addNewValuationCheck  = (event, row) => {
      getclinicorder(state.token, producer.clinic_id).then(({data}) => {
        if(data.count > 0) {
          history.push(`${prefixURL}/clinic/add-new-evaluation`,
            {
              clinicName: '',
              clinic_id: producer.clinic_id,
              producerName: producer,
              clinicId: producer.clinic_id,
              producerId: producer_id,
              bullId: row.bull_id,
              tag: row.rfid_tag,
              tag_type:row.tag_type,
              name: row.name,
              brandinput: row.brand,
              birthDate: new Date(row.birth_date*1000).toISOString().slice(0,10),
              breed: row.breed_id,
              breedothers: row.breed_other,
              producerName:props.producer.name
            })
        } else {
          let msg;
          if(state.isClinic) {
            msg = 'Not enough credits, please buy some to create new evaluation';
          } else {
            msg = 'Not enough credits, please ask the clinic owner to buy some more credits to create new evaluations';
          }
          enqueueSnackbar(`${msg}`,{variant:'warning'});
        }
      }).catch(err => {
        console.log(err)
        enqueueSnackbar('something wrong while, please reload the page',{variant:'warning'});
      })
    }

    function ConfirmDialog(props) {
      const { state: authState } = React.useContext(AuthContext);
      const {  open, id, name,setopen, fetchData } = props;
      const history = useHistory();
      const handleClose = (event) => {
            event.preventDefault();
            // save modification
              console.log('delete ')
                new Promise(() => {
                fetch(REQUEST_URL_PREFIX + 'deletebull', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                        "Authorization": authState.token
                    },
                    body: JSON.stringify({
                        bull_id: id
                    }),
                    }).then(response => {
                    if (response.status === 200) {
                        console.log("delete bull successfully")

                    } else {
                        console.log("delete bull failed")
                    }
                    return response.json()
                    }).then((content) => {
                      console.log(content);
                      //onClose();
                      fetchData();
                      setopen(false)
                    }).catch((error)=>{
                        console.log(error);
                    })
                })

          };

          return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
              <div>
                  <h6 className="text-center">Are you sure?</h6>
                    <h3 className="text-center mb-4" >You want to delete {name}</h3>
                  <Button variant="contained" color="primary" onClick={(e)=>handleClose(e)}>Yes, delete it!</Button>
                  <Button variant="contained" color="secondary" onClick={()=>setopen(false)}>Cancel</Button>
              </div>
            </Dialog>
          );

      }

  return (
    <>
        <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
        <MaterialTable
        title='Bulls Information'
        columns={[
          { title: 'Sr. No.', field: 'bull_id' },
          { title: 'Name', field: 'name'},
          { title: 'Method of ID', field: 'tag_type' },
          { title: 'Bull ID', field: 'brand' },
          { title: 'RFID Tag', field: 'rfid_tag' },
          { title: 'Breed', field: 'breed_name',
            render: rowData => rowData.breed_name == "Other" ? rowData.breed_name+': '+rowData.breed_other : rowData.breed_name,
          },
          { title: 'Published Evaluations', field: 'published_cnt',
             render: rowData => <Link to=
                {{
                    pathname:'/clinic/admin-bull-evaluations',
                    state: {
                        id: rowData.bull_id,
                        published: 1,
                        clinicId: producer.clinicId,
                        name: rowData.name,
                        tag: rowData.rfid_tag,
                        tag_type: rowData.tag_type,
                        brandinput: rowData.brand,
                        producerId: producer_id,
                        breed: rowData.breed_id,
                        breedother: rowData.breed_other,
                        birthDate: new Date(rowData.birth_date*1000).toISOString().slice(0,10),
                        clinicName: clinic_name,
                        breedList: breed_list,
                        abnormalityList: abnormality_list,
                        treatmentList: treatment_list,
                        vetList: vet_list,
                        producerName:props.producer.name
                }}}><u>{rowData.published_cnt}</u></Link> },
          { title: 'UnPublished Evaluations', field: 'unpublished_cnt',
            render: rowData =>
            <Link to=
                {{
                    pathname:'/clinic/admin-bull-evaluations',
                    state: {
                        id: rowData.bull_id,
                        published: 0,
                        clinicId: producer.clinicId,
                        name: rowData.name,
                        tag: rowData.rfid_tag,
                        tag_type: rowData.tag_type,
                        brandinput: rowData.brand,
                        producerId: producer_id,
                        breed: rowData.breed_id,
                        breedother: rowData.breed_other,
                        birthDate: new Date(rowData.birth_date*1000).toISOString().slice(0,10),
                        clinicName: clinic_name,
                        breedList: breed_list,
                        abnormalityList: abnormality_list,
                        treatmentList: treatment_list,
                        vetList: vet_list,
                        producerName:props.producer.name
                }}}><u>{rowData.unpublished_cnt}</u>
            </Link>  },

          { title: 'Birth Date', field: 'birth_date', hidden: true },
          { title: 'Other Breed', field: 'breed_other', hidden: true },
        ]}

        data={bulls}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Evaluation',

            onClick: (event, rowData) => addNewValuationCheck(event, rowData)
            // onClick: (event, rowData) =>
            // onClick: (event, rowData) => addNewValuationCheck(event, rowData)

          },
          {
            icon: 'edit',
            tooltip: 'Edit Bull',
            onClick: (event, rowData) => editBull(event, rowData)

            // onClick: (event, rowData) => addNewValuationCheck(event, rowData)
            // onClick: (event, rowData) =>
            // onClick: (event, rowData) => addNewValuationCheck(event, rowData)

          },
          {
            icon: 'delete',
            tooltip: 'Delete Bull',

            onClick:(event, rowData) => {
                  // const found = bulls.find(e=>e.clinicId===rowData.clinicId);
                  // console.log(found)
                  setOpenConfirmBull(true);
                  setBullId(rowData.bull_id);
                  setBullName(rowData.name);
                }
            // onClick: (event, rowData) => {
            //   const found = bulls.find(e=>e.bull_id===rowData.bull_id);
            //   console.log(found);
            //   setOpenConfirmBull(true);
            //   setBullId(found.bull_id);
            //   setBullName(found.name);
            },

          /*{
            icon: 'search',
            tooltip: 'Bull Details',
            onClick: (event, rowData) => alert("You want to view " + rowData.name)
          },*/
          {
            icon: 'add',
            tooltip: 'Add Bulls',
            isFreeAction: true,
            onClick: () => history.push('/add-bull', {
                producerId: producer.ID,
                producer:producer,
                clinic_id:producer.clinic_id
            } )
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          headerStyle: {
            backgroundColor: 'transperant',
            color: '#23263d'
          }
        }}/>
        {openConfirmBull?
          <ConfirmDialog id={bull_id} name={bull_name} open={openConfirmBull} setopen={setOpenConfirmBull} fetchData={fetchData} /> : null
        }

        </div>
    </>
  );
}

export default BullItemClinic
