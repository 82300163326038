import axios from './../axios'
import key from './key'
export const emailurl = process.env.REACT_APP_SENDPDFINMAIL;
export const summaryReportUrl = process.env.REACT_APP_SUMMARYMAILURL;

export const getBreeds = async (token) =>{
  return await axios.get('/allBreeds', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getReportsByClinic = async (token, id) =>{
  return await axios.get(`clinicreports/${id}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const summaryReports = async (token, url) =>{
  return await axios.get(url, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const averageDetails = async (token, url) => {
  return await axios.get(url, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}


export const getPDF = async (token,id) =>{
  let data = {
    "x_api_key" : key,
    "Authorization" : token,
    "id" : id
  }
  return await axios.post(emailurl,{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const sendPdfs = async (token,data) =>{
  data['x_api_key'] = key;
  data['token'] = token;
  return await axios.post('/sendpdfinmail',{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const sendSummaryReports = async (token,data) =>{
  data['x_api_key'] = key;
  data['token'] = token;
  return await axios.post(summaryReportUrl,{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const deletereport = async (token, report_id) => {
  return await axios.get(`deletereport?report_id=${report_id}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

