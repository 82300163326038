import React, {useState} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, Grid, Link} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withSnackbar} from 'notistack';
import { otpVerification } from '../../store/actions/auth';
import {sendOTP} from './service';
import _ from 'lodash'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: '100%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
}))(MuiDialogActions);

const CheckOTP = (props) => {

    const [otp, setOTP] = useState("");

    const onChangeText = (event) => {
        const { target } = event;
        setOTP(target.value);
    }
  
 const resentOTP = () => {
    sendOTP(props.emailId, props)
    setOTP("");
 }

 const onSubmitOTP = () => {
    otpVerification({otp}, props.token)
    props.handleChangePassword(false);
    props.sendAppOffline();
    setOTP("");
 }
  return (
    <Dialog disableBackdropClick onClose={() => props.handleChangePassword(false)} 
    fullWidth={true}
    maxWidth="sm"
     aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="customized-dialog-title" onClose={() => props.handleChangePassword(false)}>
        <center><strong>Verify your OTP</strong></center>
      </DialogTitle>
      <DialogContent>
        <TextField 
                fullWidth
                type="password"
                id="otp" 
                label="OTP" 
                variant="outlined"
                name="OTP"
                placeholder="One Time Password"
                value={otp}
                onChange={(event) => onChangeText(event)}
                />
      </DialogContent>
      <DialogActions>
      <Link href="#" onClick={resentOTP}>
            Resend Link
        </Link>
        <Button variant="contained" color="primary" onClick={onSubmitOTP}>
            Submit OTP
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withSnackbar(CheckOTP);