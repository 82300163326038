export const REQUEST_URL_PREFIX = process.env.REACT_APP_BACKEND_API;
export const GetClinicList = (token) => {
    const cliniclistUrl = REQUEST_URL_PREFIX + 'cliniclist';
    const cliniclist = async() => await fetch(cliniclistUrl, {
          method: 'GET',
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
            "Authorization": token
          }
        }).then(response => {
          if (response.status === 200) {
            console.log("get ok")
           } else {
             console.log("get failed")
           }
           return response.text();
        }).then(content => {
          const resjson = JSON.parse(content);
          var i = 0;
          let reallist = [];
          for (i in resjson.clinicList) {
              const cli = new Map();
              cli.set("clinicName", resjson.clinicList[i].clinic_name);
              cli.set("location", resjson.clinicList[i].clinic_address);
              cli.set("city", resjson.clinicList[i].clinic_city);
              cli.set("province", resjson.clinicList[i].clinic_provinceName);
              cli.set("postalcode", resjson.clinicList[i].clinic_zip);
              cli.set("phone", resjson.clinicList[i].clinic_phone);
              reallist[i] = cli;
            }
            return reallist;
        });
    cliniclist().catch(err => {
        console.log(err);
    });
}

export const AddClinic = (props) => {
  const cliniclistUrl = REQUEST_URL_PREFIX + 'cliniclist';
  const cliniclist = async() => {
    const response = await fetch(cliniclistUrl, {
        method: 'POST',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "token": props.token
        },
        body: JSON.stringify({
          clinicname: props.clinicname,
          location: props.location,
          city: props.city,
          province: props.province,
          postalcode: props.postalcode,
          phone: props.phone
        }),
      });
  }
}

export const updateClinic = (props) => {
  const cliniclistUrl = REQUEST_URL_PREFIX + 'cliniclist';
  const cliniclist = async() => {
    const response = await fetch(cliniclistUrl, {
        method: 'PATCH',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "token": props.token
        },
        body: JSON.stringify({
          clinicname: props.clinicname,
          location: props.location,
          city: props.city,
          province: props.province,
          postalcode: props.postalcode,
          phone: props.phone
        }),
      });
  }
}

export const deleteClinic = (props) => {
  const cliniclistUrl = REQUEST_URL_PREFIX + 'cliniclist';
  const cliniclist = async() => {
    const response = await fetch(cliniclistUrl, {
        method: 'DELETE',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "token": props.token
        },
        body: JSON.stringify({
          clinicname: props.clinicname
        }),
      });
  }
}
