import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table,Button,TextField} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'
import ReactDOM from "react-dom";
import { renderToString } from 'react-dom/server'
import {useSnackbar} from 'notistack';
import {sendPdfs, emailurl, sendSummaryReports } from '../../store/actions/report';
// import AuthContext from '../../../App'
import {AuthContext} from '../../App'
//const sgMail = require('@sendgrid/mail');
//sgMail.setApiKey('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');
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const EmailReport = (props) => {
  const [mode, setMode] = useState()
  const [open, setOpen] = useState(false)
  const [email_to, setEmailTo] = useState('');
  const [email_msg, setEmailMsg] = useState('');
  const [dataEmail, setDataEmail] = useState('');
  const [email, setEmail] = useState(0)
  const [reportType, setReportType] = useState('')
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {state} = useContext(AuthContext)
  const setEmailMode = (mode, type) => {
    setMode(mode)
    setOpen(!open)
    setReportType(type)
  }

  const sendMail = (dataToSend) => {
    if(props.selectedBreeds && props.selectedBreeds.length == 0) {
      enqueueSnackbar('Select atleast one report from above',{'variant' : 'warning'});
      return;
    }
    
    // let text =
    // `<div width="100%" style="text-transform:capitalize;text-align:left;font-size:1em;padding:1em;font-family: Andale Mono, monospace; background-color:#1e88e5";>` +
    // `<h1 style="color:#fff;text-align:center;">Here is the data that you have requested </h1>` +
    // `</div ><div style="text-transform:capitalize;padding:1em; font-family:Andale monospace;font-size:1.2em; background-color:#cfcfcf;">`;
   
    // const emailTable = renderToString(<JSONToHTMLTable data={props.selectedBreeds} tableClassName="emailTable table table-sm"/>)
    // text += emailTable;
    // let emails = email_to.split(',');
    // for(let i=0;i<emails.length; i++) {
    //   let email = emails[i];
    //   setEmail(email)
    //   const msg = {
    //     to: email,
    //     from: 'hrd@infrastep.com', // Use the email address or domain you verified above
    //     subject: email_msg,
    //     text: text,
    //     html: text,
    //   };
    //   console.log('email', email);
    //   // sgMail
    //   //   .send(msg)
    //   //   .then(() => {setEmailSuccess(email)}, error => {
    //   //     console.error(error);
      
    //   //     if (error.response) {
    //   //       console.error(error.response.body)
    //   //     }
    //   //   });
    //   window.Email.send({
    //     //Host : "smtp.gmail.com",
    //     //Username:"2webdesign13@gmail.com",
    //     //Password:"6D8551C77D2E6880435FF6E221E39CB15EA3",
    //     //Password:"P@ssw0rd2web",
    //     SecureToken: "f58cf558-f642-44cc-93f0-6e61cebb5a42",
    //     To : email,
    //     From : "nisrine@2web.ca",
    //     Subject : email_msg,
    //     Body :text,
            
    // }).then(() => {

    //   setEmailTo('')
    //   setEmailMsg('')
    //   enqueueSnackbar(`Email send to ${email}`,{'variant':'success'});
    // }
    //   // setEmailSuccess(email)

    //   )
      
    // }
    let data = [];
    data['ids'] = props.selectedBreeds.map(sb => sb.evaluation_id);
    data['emails'] = email_to.split(',');
    data['base_url'] = emailurl;
    sendSummaryReports(state.token, data).then(({data}) => {
      console.log(data)
      enqueueSnackbar(`Email send`,{'variant':'success'});

    }).catch((err) => {
      enqueueSnackbar(`Some Error While sending mail`,{'variant':'warning'});
    })
  }

  const sendDetailMail = () => {
    if(props.selectedBreeds.length == 0) {
      enqueueSnackbar('Select atleast one report from above',{'variant' : 'warning'});
      return;
    }
    let data = [];
    data['ids'] = props.selectedBreeds.map(sb => sb.evaluation_id);
    data['emails'] = email_to.split(',');
    data['base_url'] = emailurl;
    sendPdfs(state.token, data).then(({data}) => {
      console.log(data)
      enqueueSnackbar(`Email send`,{'variant':'success'});

    }).catch((err) => {
      enqueueSnackbar(`Some Error While sending mail`,{'variant':'warning'});
    })

  }

  useEffect(() => {
    setDataEmail(props.selectedBreeds)
    const script = document.createElement('script');
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  },[props.selectedBreeds])

  const handleEmailChecked = () => {
    setOpen(false)
    console.log(props.selectedBreeds)
    if(mode == 'summary') {
      sendMail(dataEmail)
    } else if(mode == 'detail') {
      sendDetailMail();
    }
  }

  return (
    <div>
      
    <TableContainer component={Paper}>
    <span style={{color: 'red', fontSize:'14px',float:'left',width:'100%',padding:'0 0 10px 0'}}>(* Report should be selected before you click on email send button.)</span>
    <div style={{marginTop:20}}>
      <Button variant="contained" onClick={() => setEmailMode('summary','Email Summary Report')} color="secondary">
        Email Summary Reports
      </Button>
      <Button variant="contained" onClick={() => setEmailMode('detail', 'Email Detail Report')} color="primary">
        Email Detail Reports
      </Button>
    </div>
    {
      open &&  (<div style={{marginTop:20}}>
        <form noValidate autoComplete="off">
        <h4 style={{color:'#3f6cb2'}}><strong>{reportType}</strong></h4>
          <TextField fullWidth={true} id="emailto" label="To" variant="outlined" value={email_to} onChange={(e)=>setEmailTo(e.target.value)}
           />
          <TextField id="emailmsg" label="Subject" fullWidth={true} variant="outlined" value={email_msg} onChange={(e)=>setEmailMsg(e.target.value)}/>
          <Button variant="contained"onClick={handleEmailChecked}  color="secondary" style={{float:'right'}}>
            Email Checked Reports
          </Button>
        </form>
      </div>)
    }
    
  </TableContainer>
  
  </div>
  );
}

export default EmailReport;