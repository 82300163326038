// import React from 'react';
// import PaypalExpressBtn from './PayPalExpressCheckOut';
 
// export default class payment extends React.Component {
//     render() {		
//         const onSuccess = (payment) => {
//             console.log("Your payment was succeeded!", payment);
//         }	        
//         const onCancel = (data) => {
//             // User pressed "cancel" or close Paypal's popup! 
//             console.log('You have cancelled the payment!', data);
//         }	        
//         const onError = (err) => {
//  // The main Paypal's script cannot be loaded or somethings block the loading of that script! 
//             console.log("Error!", err);
// // Since the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js" 
// // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear			 
//         }		            
//         let currency = 'CAD'; // or you can set this value from your props or state   
//         let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout 
//  return (
//             <PaypalExpressBtn 
// currency={currency}
// total={total}
// onError={onError}
// onSuccess={onSuccess}
// onCancel={onCancel}
//  />
//         );
//     }  
//  }

// import React from 'react';
// import PaypalButton from './PaypalButton';
 
// const CLIENT = {
//   sandbox: 'sb-xsalm4567922@business.example.com',
//   production:'AU22I7qM92QhtsI_mEmQMRZWGF6ZLqUbdzSDEOYIYJX_H8fdAuxo3KofjeBwnQcNjG6k_4zgZNVGaKsu',
// };
 
// const ENV = process.env.NODE_ENV === 'production'
//   ? 'production'
//   : 'sandbox';
 
// class Payment extends React.Component {
//   render() {
//     const onSuccess = (payment) =>
//       console.log('Successful payment!', payment);
 
//     const onError = (error) =>
//       console.log('Erroneous payment OR failed to load script!', error);
 
//     const onCancel = (data) =>
//       console.log('Cancelled payment!', data);
 
//     return (
//       <div>
//         <PaypalButton
//           client={CLIENT}
//           env={ENV}
//           commit={true}
//           currency={'CAD'}
//           total={100}
//           onSuccess={onSuccess}
//           onError={onError}
//           onCancel={onCancel}
//         />
//       </div>
//     );
//   }
// }
 
// export default Payment;

import React, { useState, useEffect, useContext } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { divide, set } from 'lodash';
import {getGSTRateForProvince} from '../../store/actions/clinic'
import {newPayment, cancelPayment } from '../../store/actions/order'
import { useSnackbar } from 'notistack';

import {AuthContext} from '../../App'
import TextField from '@material-ui/core/TextField';
// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

const Payment = (props) => {
    const classes = useStyles();
    let {state} = useContext(AuthContext)
    const [province, setProvince] = useState()
    const [total_amount, setAmount] = useState(0)
    const [Amount, setOrigAmount] = useState(0)
    const [taxes, setTaxes] = useState(0)
    const [form, setForm] = useState(0)
    const [gst, setGST] = useState(0)
    const [pst, setPST] = useState(0)
    const [qst, setQST] = useState(0)
    const [hst, setHST] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getGSTRateForProvince(state.token).then(({data}) => {
            // console.log('data', data)
            setProvince(data)
        }).catch(err => {
            // console.log('err', err)
        })
    },[])
    const onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
                // console.log("The payment was succeeded!", payment);
        let order = {
          'clinic_id' : state.user.clinicId,
          'paypal_order_id' : payment.paymentID,
          'amount' : total_amount,
          'form_count' : form,
          'paypal_data' : payment,
          'tax_details' : {
            "gst" : gst,
            "qst" : qst,
            "hst" : hst,
            "pst" : pst
          },
          'status' : 'success'
        };

        newPayment(state.token, order).then(({data})=> {
          // console.log('new payment made ')
          if(payment.paid) {
            enqueueSnackbar('Purchase completed successfully',{'variant':'success'});
          } else {
            enqueueSnackbar('Purchase Failed',{'variant':'warning'});
          }
        if(payment.paid) {
          props.getOrders();
        }

        }).catch(err => console.log(err))
        
                // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onCancel = (cancelData) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', cancelData);
        enqueueSnackbar('Unable to make purchase',{'variant':'warning'});
        cancelPayment(state.token, cancelData).then(({data})=> {
          // console.log('payment failed and saved')

        }).catch(err => console.log(err))
        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        // console.log("Error!", err);
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }
    const handleFormFilled = (event) => {
        let gst = (event.target.value*province.gst)/100;
        let qst = (event.target.value*province.qst)/100;
        let pst = (event.target.value*province.pst)/100;
        let hst = (event.target.value*province.hst)/100;
        setGST(gst)
        setHST(hst)
        setPST(pst)
        setQST(qst)
        let tax = gst + qst + pst + hst;
        let ta = tax + parseInt(event.target.value)
        setTaxes(tax)
        setOrigAmount(event.target.value)
        setAmount(ta)
        setForm(event.target.value)
    }

    let env = process.env.REACT_APP_NODE_ENV === "production" ? 'production' : 'sandbox'; // you can set here to 'production' for production
    let currency = 'CAD'; // or you can set this value from your props or state
    let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
    // console.log('process',env)
    const client = {
        sandbox: process.env.REACT_APP_PAYPAL_SANDBOX,
        production: process.env.REACT_APP_PAYPAL_PRODUCTION,
    }
    // console.log(client);
    
    return (
        <div>

        <Card className={classes.root}>
        <CardContent>
        
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                Taxes for Province <b>{province && province.province}</b> is <b>GST</b> {province && province.gst}, <b>PST</b> {province && province.pst},
                 <b> QST</b> {province && province.qst} and <b>HST</b> {province && province.hst}
              </Typography>
            </div>
            <br/>
          </div>
          <div className="row">
              <div className="col-lg-4">
                  <TextField fullWidth={false} id="form" error={form < 25} helperText="Buy atleast 25 forms" type="number" onChange={(event) => handleFormFilled(event)} variant="outlined"  value={form} label='form' />
              </div>
            </div>
        </div>
        <br/>
        <b>Total Amount(CAD):  {total_amount.toFixed(2)} </b>
        <br/>
        {form > 0 ?
            (<span> {Amount} (Base Amount) + {taxes.toFixed(2)} (Taxes) [GST: {gst.toFixed(2)}, PST: {pst.toFixed(2)},
            QST: {qst.toFixed(2)}, HST: {hst.toFixed(2)}]</span>): ''}
        </CardContent>
        <CardActions>
            {
              form >= 25 ? (<PaypalExpressBtn env={env} client={client} currency={currency} total={total_amount} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />) : ''
            }
            
        </CardActions>
        </Card>
        </div>
        
    );
} 

export default Payment;