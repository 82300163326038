import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import PublishUnpublished from '../../components/evaluation/PublishUnpublished';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const PublishedEvaluation = () => {
  const classes = useStyles();
  function urlList() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Producers </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/">
                  <span>Bob Young</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Published Evaluations</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
      {urlList()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Past Evaluations</strong></span>
		    </div>
          <div className={classes.root}>
            <PublishUnpublished isPublished={true} />
          </div>
		    </div>
    </>
  );
}

export default PublishedEvaluation;
