import React from 'react';
import {TableBody, Button,Table, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import {useContext, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import Payment from '../../components/order/payment';
import {AuthContext, OrderContext} from '../../App'
import {getClinicOrders} from '../../store/actions/order'
import {getclinicorder } from '../../store/actions/clinic'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: '100%',
  },
}));

const Orders = (props) => {
  const classes = useStyles();
  let {state} = useContext(AuthContext)
  const [payments, setPayments] = useState([])
  const [orderCount, setOrderCount] = useState(0)
  useEffect(() => {
    getOrders();
  },[])

  const getOrders = () => {
    getClinicOrders(state.token).then(({data}) => {
      console.log(data.paymentList)
      setPayments( Object.values(data.paymentList))
    }).catch(err => {
      console.log(err)
    })
    getClinicCurrentCount()
  }

  const getClinicCurrentCount = () => {
    getclinicorder(state.token, state.user.clinicId).then(({data}) => {
      setOrderCount(data.count)
    }).catch(err => {
      console.log(err)
    })
  }
  
  function url_head() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Orders </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Orders</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
    
      {url_head()}
       <OrderContext.Consumer> 
         { 
           ({order_count, set_order_count}) => {
            set_order_count(orderCount)
            return ( 
              <>
              <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
              <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
                <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Orders</strong></span>
              </div>
                <div className={classes.root}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" style={{width:270}}>Order ID</TableCell>
                          <TableCell align="left" style={{width:170}}>Amount Paid</TableCell>
                          <TableCell align="left" style={{width:170}}>Forms</TableCell>
                          <TableCell align="left">Date Purchased</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payments && payments.map((row, i) => {
                          return (
                            <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                              <TableCell align="left" >
                                <Link to={`/order/${row.order_id}`}>
                                  {row.paypal_order_id}<br/>
                                </Link>
                              </TableCell>
                              <TableCell align="left">{row.amount}</TableCell>
                              <TableCell align="left">
                                {row.form_count}
                              </TableCell>
                              <TableCell align="left">
                                {row.date_purchased}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  
                </div>
              </div>
            <Payment getOrders = {getOrders} />
            </>
           ) 
          
         }} 
    </OrderContext.Consumer>
        </>
  );
}

export default Orders;



// const singleOrder = (props) => {
//   const [open, setOpen] = useState();
//   useEffect(() => 
//     setOpen(props.open)
//   ,[])
//   return (
//     <div>
      
//       <Dialog
//         fullScreen={true}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="responsive-dialog-title"
//       >
//         <DialogTitle id="responsive-dialog-title">{"Use Google's location service?"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Let Google help apps determine location. This means sending anonymous location data to
//             Google, even when no apps are running.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose} color="primary">
//             Disagree
//           </Button>
//           <Button onClick={handleClose} color="primary" autoFocus>
//             Agree
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }