import React, {useEffect, useRef, useState, useContext } from 'react';
import {makeStyles, Grid, Button,TextField,RadioGroup,FormControlLabel, FormControl, Select,Radio} from '@material-ui/core';
import {Link} from 'react-router-dom'
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator'
import {useHistory, useLocation } from 'react-router-dom'
import { getBreeds } from '../../store/actions/report'
import { getBull,updateSingleBull } from '../../store/actions/bull'
import {getSingleProducer  } from '../../store/actions/producer'
import MenuItem from '@material-ui/core/MenuItem';
import { AuthContext } from "../../App"
import {withSnackbar} from 'notistack'

import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: '100%',
  },
}));

const EditBull = (props) => {
  debugger;
  let location = useLocation()
  console.log("location.state.producer",location.state.producer)
  let {state} = useContext(AuthContext)
  const formRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const [breeds, setBreeds] = useState([]);
  const [producer, setProducer] = useState(null)
  const [breedName, setBreedName] = useState('')
  const [breed, setBreed] = useState('');
  const [dateError, setDateError] = useState(false)
  const [breedError, setbreedError] = useState(false)
  const [birthday, setBirthday] = useState('');
  const [age_year, setAgeYear] = useState(0);
  const [age_month, setAgeMonth] = useState(0);
  //const clinic_id = location.state.clinicId
  const [bull, setBull] = useState({
    producer_id:"",
    name: '',
    tag_type: '',
    brand: '',
    rfid_tag: '',
    breed_id: '',
    breed_other: '',
    birth_date: '',
    age_year:'',
    age_month:'',

  })
  const [rebull, setReBull] = useState({
    producer_id:"",
    name: '',
    tag_type: '',
    brand: '',
    rfid_tag: '',
    breed_id: '',
    breed_other: '',
    birth_date: '',
    age_year:'',
    age_month:''
  })
  const resetBullValues = () => {
   history.goBack()
  }
  const addEvaluation = () => {
    bull.birth_date = birthday
    if(bull.breed_id == undefined){
      setbreedError(true)
      return
    }
    setbreedError(false)
    if(bull.birth_date == ""){
      setDateError(true)
    return
    }
    setDateError(false)
    updateSingleBull({...bull,bull_id:props.match.params.id,producer_id:props.match.params.producer_id }, state.token).then(({data}) => {
      props.enqueueSnackbar('Updated Bull Successfully',{variant:"success"});
      getBull(bull.bull_id, state.token).then(({data}) =>{
        history.push('/clinic/add-new-evaluation', {
          clinicName: '',
          //clinic_id: location.state.clinic_id,
          clinic_id: producer.clinic_id,
          producerName: producer.name,
          //clinicId: location.state.clinic_id,
          clinicId: producer.clinic_id,
          producerId: producer.ID,
          bullId: data.bull_details.bull_id,
          tag: data.bull_details.rfid_tag,
          name: data.bull_details.name,
          brandinput: data.bull_details.brand,
          birthDate: new Date(data.bull_details.birth_date*1000).toISOString().slice(0,10),
          breed: data.bull_details.breed_id,
          breedothers: data.bull_details.breed_other,
          tag_type:data.bull_details.tag_type,
        });
      })
    }).catch(err => {
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    })
  }

  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    console.log('name and value', name , value)
    console.log("name, value",name, value )
    if(name == 'birth_date') {
      if(value == ""){
        setDateError(true)
      return
      }
      setDateError(false)
      calculateAge(event)
    }
    if(name == 'breed_id') {
      if(value == ""){
        setBreed(value)
        setbreedError(true)

      return
      }
      setbreedError(false)
      console.log('breed selected in edit bukk', value)
      setBreed(value)
      const selectedBreed = breeds.find(item=>item.breed_id===value) || { breed_name: '', breed_id: '' };
      setBreedName(selectedBreed?.breed_name)
    }
    setBull(prevForm => ({
      ...prevForm,
      [name]:value
    }))
  }

  useEffect(() => {
       // Custom rule to validate the date format YYYY-MM-DD
       ValidatorForm.addValidationRule('isValidBirthDate', (value) => {
           const regex = /^\d{4}-\d{2}-\d{2}$/;
           if(regex.test(value)!==true) {
             setDateError(true);
             return;
           }
            setDateError(false);
            // Parse the date parts to integers
          const [year, month, day] = value.split('-').map(Number);
           // Check if the date parts form a valid date
           const date_comp = new Date(year, month - 1, day);
           if (
             date_comp.getFullYear() === year &&
             date_comp.getMonth() === month - 1 &&
             date_comp.getDate() === day
           ) {
             setDateError(false);
             return true;
           }else{
             setDateError(true);
             return;
           }
       });
   }, []);

  const handleChange = ( event) => {
    const { value } = event.target;
    console.log('Controlled Change', value);
     let formattedDate = '';


    // Ensure that only digits are considered
    const numericValue = value.replace(/\D/g, '');
    console.log(numericValue)
    console.log(numericValue.length)
    // Format the date string
    if(numericValue.length <= 4){
      formattedDate = value;
    }else if(numericValue.length <=6 ){
       formattedDate = numericValue.slice(0, 4)+ '-' + numericValue.slice(4, 6);
    }else if(numericValue.length <=8){
       formattedDate = numericValue.slice(0, 4)+ '-' + numericValue.slice(4, 6) + '-' + numericValue.slice(6, 8);
    }

    console.log(formattedDate)

    if(new Date(formattedDate).getTime() > new Date().getTime()){
      setDateError(true)
      return
    }
    setDateError(false)


    setBirthday(formattedDate)
    let date = new Date(formattedDate)
    let ageDifMs = Date.now() - date.getTime();
    let ageDate = new Date(ageDifMs)
    setAgeMonth(ageDate.getMonth())
    setAgeYear(Math.abs(ageDate.getUTCFullYear() - 1970))

  };


  const calculateAge = (event) => {
    if(new Date(event.target.value).getTime() > new Date().getTime()){
      setDateError(true)
      return
    }
    setDateError(false)
    let date = new Date(event.target.value)
    let ageDifMs = Date.now() - date.getTime();
    let ageDate = new Date(ageDifMs)
    setBirthday(event.target.value)
    setAgeMonth(ageDate.getMonth())
    setAgeYear(Math.abs(ageDate.getUTCFullYear() - 1970))
  }

  const calAgeAndYear = (value) => {
    let date = new Date(value)
    let ageDifMs = Date.now() - date.getTime();
    let ageDate = new Date(ageDifMs)
    setAgeMonth(ageDate.getMonth())
    setAgeYear(Math.abs(ageDate.getUTCFullYear() - 1970))
  }

  const calulateAgeFromMonth = (event) => {
    setAgeMonth(event.target.value)
    const date = new Date()
    let months = event.target.value?event.target.value:0;
    console.log('age', age_year );
    let total_months = age_year*12 + parseInt(months);
    console.log('total months', total_months);

    var startdate = moment().subtract(total_months, "months").format("YYYY-MM-DD");
    setBirthday(startdate)
  }
  const calcluateAgeFromYear = (event) =>{
    const date = new Date()
    let year = date.getFullYear()
    setAgeYear(event.target.value)
    let age = event.target.value? event.target.value: 0
    let total_months = age*12 + parseInt(age_month);
    console.log('total months', total_months);
    var startdate = moment().subtract(total_months, "months").format("YYYY-MM-DD");
    console.log('start date', startdate);
    setBirthday(startdate)
  }

  const updateBull = () =>{
    bull.birth_date = birthday
    if(bull.breed_id == undefined || bull.breed_id == ""){
      setbreedError(true)
      return
    }
    setbreedError(false)
    if(bull.birth_date == ""){
      setDateError(true)
    return
    }
    setDateError(false)
    updateSingleBull({...bull,bull_id:props.match.params.id,producer_id:props.match.params.producer_id }, state.token).then(({data}) => {
      props.enqueueSnackbar('Updated Bull Successfully',{variant:"success"});
      history.go(-1)
    }).catch(err => {
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    })
  }
  const getProducer = () => {
    getSingleProducer(props.match.params.producer_id,state.token).then(({data}) => {
      setProducer(data.producerDetail)
    })
  }
  useEffect(() => {
    getProducer()
    getBreeds().then((res) => {
      setBreeds(res.data.breedsResults)
      let bull_id = props.match.params.id
      getBull(bull_id, state.token).then(({data}) =>{
        setBull(data.bull_details)


        const selectdData = res.data.breedsResults.find(item=>item.breed_id===data.bull_details.breed_id) || {breed_id: '', breed_name: ''};
        setBreedName(selectdData?.breed_name);
        var birthDay = new Date(data.bull_details.birth_date*1000)

        birthDay=birthDay.toISOString().slice(0,10);
        setBirthday(birthDay)
        calAgeAndYear(birthDay)
      })
    }).catch(err => {
      console.log("err", err)
    })

  }, [])
  console.log("this is props", props)
  function url_head() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Edit Bull </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/clinic/producer/${location.state.producer.ID}`}>{location.state.producer.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Edit Bull</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
      {url_head()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Edit Bull</strong></span>
		    </div>
          <ValidatorForm ref={formRef}  noValidate autoComplete="off">
            <div className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs sm={9} style={{marginLeft:10}}>
                  <h4 className="mt-3" style={{color:'#000', marginTop:'10px'}}>Method of identification</h4>
                  <RadioGroup row name="tag_type" value={bull.tag_type} onChange={(event) => onChangeHandler(event)} style={{marginLeft:10}}>
                    <FormControlLabel value="earTag" control={<Radio />} label="Ear Tag" />
                    <FormControlLabel value="tattoo" control={<Radio />} label="Tattoo" />
                    <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                  </RadioGroup>
                </Grid>
                <Grid item xs sm={3}>
                  <TextField
                    fullWidth
                    type="text"
                    name="brand"
                    id="brand"
                    variant="outlined"
                    label="Bull ID(no special characters please)"
                    value={bull.brand}
                    onChange={(event) => onChangeHandler(event)}
                  />
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={1}>
                <Grid item xs sm={3}>
                  <TextField
                    fullWidth
                    type="text"
                    name="rfid_tag"
                    value={bull.rfid_tag}
                    id="rfid-tag"
                    variant="outlined"
                    label="RFID Tag"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </Grid>
                <Grid item xs sm={3}>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    value={bull.name}
                    id="name"
                    variant="outlined"
                    label="Name"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </Grid>
                <Grid item xs sm={3}>
                  <TextValidator required id="select-breed" select label="Select Breed" variant="outlined" name="breed_id" value={bull.breed_id} onChange={(e)=>onChangeHandler(e)}
                        validators={['required', 'maxStringLength:100']} errorMessages={['this field is required', 'the max length is 100']} >
                          <MenuItem value="">None</MenuItem>
                          {breeds?breeds.map((breed) => (
                            <MenuItem key={breed.breed_id} value={breed.breed_id}>
                              {breed.breed_name}
                            </MenuItem>
                          )):''}
                      </TextValidator>
                      {breedError?<span style={{color:'red', marginLeft:'10px'}}>Choose a breed</span>:null}
                </Grid>
                {

                breedName == 'Other' ?
                (<Grid item xs sm={3}>
                  <TextField
                    fullWidth
                    type="text"
                    value={bull.breed_other}
                    onChange={(e)=>onChangeHandler(e)}
                    name="breed_other"
                    id="breed_other"
                    variant="outlined"
                    label="Other Breed Name"
                  />
                </Grid> ): null
                }
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs sm={3}>
                <TextValidator  required  id="date" label="Date of Birth"  variant="outlined" placeholder="YYYY-MM-DD" name="birth_date"
                type="text" onChange={handleChange} value={birthday} InputLabelProps={{  shrink: true,}}
                validators={['required','isValidBirthDate', 'maxStringLength:10']} errorMessages={['this field is required','', 'the max length is 10']}/>
                  {dateError?<span style={{color:'red', marginLeft:'10px'}}>Date must be in YYYY-MM-DD format</span>:null}
                </Grid>
               <span className="OrWrap">OR</span>
                <Grid item xs sm={3}>
                <TextValidator id="age-years" label="Age: Years" variant="outlined" value={age_year} onChange={(e)=>calcluateAgeFromYear(e)}/>
                </Grid>
                <Grid item xs sm={3}>
                  <TextValidator id="age-months" label="Age: Months" variant="outlined" value={age_month} onChange={(e)=>calulateAgeFromMonth(e)}/>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                <Button variant="contained" disabled={dateError} color="primary" className={classes.margin} onClick={updateBull}>Update</Button>
                <Button variant="contained" disabled={dateError} className="MuiButton-containedSecondary" onClick={addEvaluation}>Add Evaluation</Button>
                <Button style={{marginLeft:10}} color="primary" variant="contained" onClick={resetBullValues}>Cancel</Button>
                </Grid>
              </Grid>
            </div>
          </ValidatorForm>
		    </div>
    </>
  );
}

export default withSnackbar(EditBull);
