import React, {useState, useRef, useContext} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withSnackbar} from 'notistack'
import {sendOTP} from './service'
import _ from 'lodash'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: '100%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
}))(MuiDialogActions);

const VerifyEmailModal = (props) => {
  
 const submitEmail = () => {
  sendOTP(props.emailId, props)
  props.hadleOTPModal(true);
  props.handleChangePassword(false);
 }

  return (
    <Dialog disableBackdropClick onClose={() => props.handleChangePassword(false)} 
    fullWidth={true}
    maxWidth="sm"
     aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="customized-dialog-title" onClose={() => props.handleChangePassword(false)}>
        <center><strong>Verify your email address</strong></center>
      </DialogTitle>
      <DialogContent>
        <TextField 
                fullWidth
                defaultValue={props.emailId}
                type="text"
                id="email" 
                label="Email" 
                variant="outlined"
                name="Email"
                placeholder="Email"
                disabled
                />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={submitEmail}>
            Verify Your Email
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withSnackbar(VerifyEmailModal)
