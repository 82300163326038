import axios from "../../store/axios";
import key from '../../store/actions/key';

export const sendOTP = (email, vm) =>{

    axios.post('/clinic/sendotp', {email}, {
      headers:{
        "x-api-key": key,
      }
    }).then(({data}) =>{
      vm.enqueueSnackbar(`OTP has been sent`,{variant:"success"});
    }).catch(err => {
      // console.log('erro', err.response)
      if(err.response.status == 409) {
        vm.enqueueSnackbar(`${err.response.data.message}`,{variant:"warning"});
      } else {
        vm.enqueueSnackbar('Incorrect Email/Password',{variant:"warning"});
      }
    })
  }