import React, { useState, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { red } from '@material-ui/core/colors';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAlert } from "react-alert";

import { REQUEST_URL_PREFIX } from '../Utils'
import { AuthContext } from "../App";

const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    label: {
      color: red[600]
    }
  });
  
export default function ChangePwd(props) {
  const alert = useAlert();
  const history = useHistory();
  const { state: authState, dispatch } = useContext(AuthContext);


      const classes = useStyles();
      const { open, onClose } = props;
      const [newpwd, setNewPwd] = useState('')
      const [confirm_password, setConfirmPwd] = useState('')
      const [consistpwd, setConsistPwd] = useState(true)
      
      const handleClose = (event, confirm) => {
        if (confirm) {
          if (newpwd !== confirm_password) {
            setConsistPwd(false)
            setNewPwd('')
            setConfirmPwd('')
            return;
          }
          console.log('change pwd')
          new Promise(() => {
            fetch(REQUEST_URL_PREFIX + 'changepassword', {
                method: 'POST',
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
                  "Authorization": authState.token
                },
                body: JSON.stringify({
                  id: authState.userId,
                  password: newpwd
                }),
              }).then(response => {
                if (response.status === 200) {
                  alert.success('Password changed successfully')
                  history.push('/')
                  dispatch({type: "LOGOUT"});
                } else {
                  console.log("change pwd failed")
                }
                return response.json()
              }).then((content) => {
                console.log(content)
                onClose()
              }).catch((error)=>{
                  console.log(error);
                  onClose()
              })
          })
        } else {
          onClose()
        }
      };
      
      return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
          <div>
              <h6 className="text-center">Change your password</h6>
                <div>
                  <TextField required label="New password" variant="outlined" name="new_password" type="password" vaule={newpwd} onChange={(e)=>setNewPwd(e.target.value)} />
                </div>
                <div>
                  <TextField required label="Confirm password" variant="outlined" name="confirm_password" type="password" vaule={confirm_password} onChange={(e)=>setConfirmPwd(e.target.value)}/>
                </div>
                {consistpwd?
                  null: <label className={classes.label} > Inconsistent password </label>
                }
                <div>
                  <Button variant="contained" color="primary" className={classes.margin} onClick={(e)=>handleClose(e, true)}>Reset password</Button>
                  <Button variant="contained" color="secondary" className={classes.margin} onClick={(e)=>handleClose(e, false)}>Cancel</Button>
              </div>
          </div>
        </Dialog>
      );
    
  }
  