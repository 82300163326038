import React, { useState, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router'
import { Link, useHistory, useLocation } from 'react-router-dom';

import ChangePwd from '../user-pages/ChangePwd';
import { AuthContext } from "../App";

 function Navbar() {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const [open_change_pwd, setOpenChangePwd] = useState(false);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  const handleClose = () => {
    setOpenChangePwd(false);
    window.location.reload(false);
  };

  const handleLogout = (event) => {
    //event.preventDefault();
    history.push('/')
    dispatch({type: "LOGOUT"});
    
  };

  return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img src="/images/logo-mini.png" alt="logo" /></a>
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <i className="mdi mdi-menu"></i>
          </button>
          
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{state.userEmail}</span>
                  <img className="img-xs rounded-circle" src="/images/admin_icon.png" alt="Profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3 user-logout">
                  <Dropdown.Item onClick={()=>setOpenChangePwd(true)} eventKey="1">Change Password</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout} eventKey="2">Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>

          {open_change_pwd?
            <ChangePwd open={open_change_pwd} onClose={handleClose} /> : null
          }
        </div>
      </nav>
    )
}

export default withRouter(Navbar);
