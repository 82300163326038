import { date } from 'yup'
import axios from './../axios'
import key from './key'

export const getClinicOrders = async (token) =>{
  return await axios.get('/clinicpaymentlist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllOrders = async (token) =>{
  return await axios.get('/paymentlist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const newPayment = async (token, data) => {
  return await axios.post('/newpayment',{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const cancelPayment = async (token, data) => {
  return await axios.post('/cancelpayment',{...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const getSingleOrder = async (token, oid) => {
  return await axios.get(`paymentdetails/${oid}`,{
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}