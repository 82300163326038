import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import SignatureCanvas from 'react-signature-canvas';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    minWidth: '100%',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: '100%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DeleteBull = (props) => {
  return (
    <Dialog onClose={() => props.handleBullDialog(false)} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <center>
              <HighlightOffIcon style={{fontSize:100, color:'#ffa133'}} />
            </center>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <center>
              <span style={{fontSize:20, color:'blue'}}>
                <b>Are you Sure?</b>
              </span><br/>
              <span style={{fontSize:16}}>
                Want to delete {props.bull && props.bull.name} ({props.bull && (props.bull.bull_id)})
              </span>
            </center>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <center style={{marginTop:20}}>
              <Button variant="contained" color="secondary" onClick={() => props.deleteSubmit()}>
                Yes, delete it!
              </Button>
              <Button variant="contained" color="primary" onClick={() => props.handleBullDialog(false)}>
                cancel
              </Button>
            </center>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
export default DeleteBull
