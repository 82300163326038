import Dexie from 'dexie';
const DBV = Math.round(10);

const db = new Dexie('myDb');
db.version(DBV).stores({
    abnormalitylist: `id, value`,
    allProvinces: `provinceId, gst, hst, province_code, province_name, pst, qst`,
    breedlist: `breed_id, breed_name`,
    clinicordercount: `count, isSuccess, status`,
    ownInformation: `clinicId, province_id, city, address, created, email, fax, is_approved, is_archived, name, phone, postal_code, province, serial_number_count, total_producer, updated, username`,
    treatmentlist: `treatment_id, created, name, updated`,
    userData: `userId, clinicId, email, isClinic, timestamp, username, isVet`,
    vetList: `vet_id, clinic_id, created, signature, updated, vet_name`,
    vetsdetails: `vet_id, clinic_id, clinic_name, created, hash_id, is_archived, name, signature, updated`,
    
    record: `typeId, id, type, data`,

    producers: `id, value`,

    bulls: `id, value`,

    evaluations: `id, value`,
});

export default db;
