import axios from './../axios'
import key from './key'

export const createProducer = async (producer, token) =>{
  return await axios.post('/newproducer', producer, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const getProducer = async (token) =>{
  return await axios.get('/clinicProducers', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getClinicProducers = async(token, cid) => {
  return await axios.get(`/clinicproducerslist/${cid}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getSingleProducer = async (id, token) =>{
  return await axios.get(`/producerdetails/${id}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const updateProducer = async (producer, token) =>{
  console.log("token", producer, token)
  return await axios.post(`/updateproducer`,producer, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getProducerBullDetails =  async (pid, token) => {
  return await axios.get(`/producerbasicwithbullinfo/${pid}`, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const deleteProducer =  async ( token, pid) => {
  return await axios.post(`/deleteproducer`,{producer_Id:pid}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getVetProducers = async(token) => {
  return await axios.get('vet/producers', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}


