import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, InputBase, IconButton,Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid';
import ProducerItems from '../../components/producer/ProducerItems';
import DetailsClinic from '../../components/clinic/DetailsClinic';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ClinicDetails = () => {
  const classes = useStyles();
  function homeClinic() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Producer </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                <span>Home</span>
              </li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
      {homeClinic()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Producer Information</strong></span>
		    </div>
          <div className={classes.root}>
            <DetailsClinic />
            <Grid>
              <Button style={{marginTop:20}} variant="contained" color="secondary">
                Edit Clinic
              </Button>
            </Grid>
          </div>
		    </div>
        <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
          <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
            <span className={`${classes.producer_container} MuiTypography-root MuiTypography-h5 mb-0` }  style={{color:'#fff'}}
            ><strong>Producers</strong></span>
            
            <InputBase
              className={classes.input}
              placeholder="Search Producers"
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Button style={{float:'right'}}variant="contained" color="primary">
              Add Producer
            </Button>
          </div>
          <ProducerItems />
        </div>
    </>
  );
}

export default ClinicDetails;
