import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useAlert } from "react-alert";
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {withSnackbar} from 'notistack'
import {submitEmailForUserPassword} from '../store/actions/user'
import Loading from '../components/common/Loading'
import ReactLoading from 'react-loading';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table'
import {Table} from 'react-bootstrap'

const useStyles = makeStyles(() => ({
  label: {
    color: red[600]
  }
}));

const AdminResetPwd = (props)  => {
  let history = useHistory()
  const alert = useAlert();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [loginAs, setLoginAs] = useState('clinic')
  const [emailerror, setEmailError] = useState('');
  const [loading, setLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (pattern.test(String(email).toLowerCase())) {
      setEmailError(false);
      setLoading(true)
      submitEmailForUserPassword({loginAs,email}).then(({data})  => {
        props.enqueueSnackbar('Check you inbox and submit OTP',{variant:"success"});
        
        history.push("/change_password", {
          email:email,
          loginAs
        })
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        if(err && err.response && err.response.status===404){
          props.enqueueSnackbar( err.response.data.message,{variant:"error"});
        }
        console.log('err', err, err.response)
      })
    } else {
      setEmailError(true);
    }
  }

  return (
      // <div className="login-wrap">
      <Card>
        <Typography className="MuiToolbar-root MuiToolbar-regular MTableToolbar-root-30 MuiToolbar-gutters" gutterBottom>
              <div className="MTableToolbar-title-24">
                <h6 className="MuiTypography-root MuiTypography-h6">Refetch</h6>
              </div>
              </Typography>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 login-form">
              <div className="auth-form-light text-left">
                <div className="brand-logo mb-0">
                {/* <h1><font color = "white">Verify Email</font></h1> */}
                  {/* <img src="/images/logo.png" alt="logo" /> */}
                </div>
            <div style = {{textAlign: "left"}}>
                <h2 className="font-weight-light"><center></center></h2>
                {/* <h6 className="font-weight-light">Verify your email address.</h6> */}
                <Form className="pt-3" onSubmit={handleSubmit}>
                  {/* <Form.Group className="d-flex search-field">
                    <Form.Control type="text" placeholder="Email*" size="lg" className="h-auto"
                      value={email} onChange={(e)=>setEmail(e.target.value)} id="email" name="email" />
                  </Form.Group> */}
                  
              <Table bordered responsive size="lg">
                <thead>
                  <tr>
                    <th><b>Type:</b></th>
                    <th></th>
                  </tr>
                  <tr>
                    <th><b>Status:</b></th>
                    <th></th>
                  </tr>
                  </thead>
                  </Table>

                  {/* <strong>Type:</strong>
                  <br/>
                  <strong>Status:</strong> */}
                  {emailerror?
                    <label className={classes.label} >Email is invalid</label> : null
                  }
                  {/* <Form.Group>
                    <Form.Label>Change password for:</Form.Label>
                      <Form.Control onChange={(event) => setLoginAs(event.target.value)} value={loginAs} as="select">
                        <option value="admin">Admin</option>
                        <option value="clinic">Clinic</option>
                        <option value="vet">Vet</option>
                      </Form.Control>
                  </Form.Group> */}
                  <div className="mt-3">
                    <input type="submit" disabled={loading} value="Refetch" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"/>
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    {/* <Link to="/" className="auth-link text-black"><u>Login?</u></Link> */}
                  </div>
                </Form>
              </div>
              </div>
            </div>
          </div>
        </div>  
       {/* </div> */}

      </Card>

      
    )
  
}
export default withSnackbar(AdminResetPwd)