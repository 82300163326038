import React, {useState, useRef, useContext} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {Button, Dialog, TextField, Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {withSnackbar} from 'notistack'
import {sendOTP} from './service'
import _ from 'lodash'
import { getUpdatedRecord, deleteProducerFromRecord, deleteBullFromRecord, deleteEvaluationFromRecord } from '../../indexedDB';
import { syncProducerData, syncBullData, syncEvaluationData } from '../../store/actions/clinic';
import Loading from '../../components/common/Loading';
import clinicordercountDB from '../../DB/clinicOrderCount';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    minWidth: '100%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center'
  },
}))(MuiDialogActions);

const SyncDataModal = (props) => {
  const { token, handleSyncingDone, userId, enqueueSnackbar } = props;
  const [loading, setLoading] = useState(false);
 const submitEmail = () => {
  sendOTP(props.emailId, props)
  props.hadleOTPModal(true);
  props.handleChangePassword(false);
 }

const syncProducerList = async(token, prod) => {
  try{
    const {data} = await syncProducerData(token, prod);
    return Promise.all(data.success.map(async(prodId) => {
      await deleteProducerFromRecord(prodId, userId);
    }));
  } catch(err){
  }
}

const syncBullList = async(token, bull) => {
  try{
    const {data} = await syncBullData(token, bull);
    return Promise.all(data.success.map(async(bullId) => {
      await deleteBullFromRecord(bullId, userId);
    }));
  } catch(err){
  }
}

const syncEvaluationList = async(token, evaluation) => {
  try{
    const {data} = await syncEvaluationData(token, {evaluations: evaluation});
    if(data.failed && data.failed.length){
      enqueueSnackbar(data.message, {variant:"error"});
    }
    return Promise.all(data.success.map(async(evaluationId) => {
      await deleteEvaluationFromRecord(evaluationId, userId);
    }));
  } catch(err){
  }
}

const clearAllTheData = (myDB) => {
  myDB._storeNames.forEach(async(tableName) => {
    const data = await myDB[tableName].clear();
  });
}

const syncAllTheEvaluationData = async(evaluationList) => {
  const EVAL_BATCH_COUNT = 10;
  for(let i=0; i<evaluationList.length; i+=EVAL_BATCH_COUNT){
    const evalId = await syncEvaluationList(token, evaluationList.slice(i, i+EVAL_BATCH_COUNT));
  }
  return Promise.resolve();
  //   evaluationList.map(async(evaluation) => {
    
  //   await deleteEvaluationFromRecord(evaluation.evaluation_id, userId);
  // }));
}

// const syncAllTheBullData = async(bullList, producerId) => {
//   return Promise.all(bullList.map(async(bull) => {
//     bull['producer_id'] = producerId;
//     const bullId = await syncBullList(token, bull);
//     const evaluationList = await getEvaluationDataWithBullId1(bull.bull_id);
//     await syncAllTheEvaluationData(evaluationList, bullId)
//   }));
// }

const doSyncing = () => {
  setLoading(true);
  const { isOffliceBtnClicked } = props;
  getUpdatedRecord("producer").then(async (resourcesArray) =>{
    const producers = resourcesArray.map((resource) => resource.data);
    const producerIds = await syncProducerList(token, {producers});
    let bullList = await getUpdatedRecord("bull");
    bullList = bullList.map((resource) => resource.data);
    const bullIds = await syncBullList(token, {bulls: bullList});
    let evaluationList = await getUpdatedRecord("evaluation");
    evaluationList = evaluationList.map((resource) => resource.data);
    // const evaluationIds = await syncEvaluationList(token, evaluationList);
    await syncAllTheEvaluationData(evaluationList)
    // clearAllTheData(clinicordercountDB);
    setLoading(false);
    handleSyncingDone(false);
    if(!isOffliceBtnClicked){
      window.location.reload();
    }
  }).catch(err=>{
    console.log("err", err)
    setLoading(false);
  })
  
 }

  return (
    <Dialog disableBackdropClick onClose={() => handleSyncingDone(false)} 
    fullWidth={true}
    maxWidth="sm"
     aria-labelledby="customized-dialog-title" open={props.open}>
       
        <DialogTitle id="customized-dialog-title" onClose={() => handleSyncingDone(false)}>
          <center><strong>Sync Your Offline Data to Online Data</strong></center>
            <big style={{color: 'red'}}>**Warning</big>:
          <span><strong><small style={{color: 'red'}}>
            <ul>
              <li>Please sync your data before closing. It is absolutely mandatory to sync your data before closing this pop up.</li>
              <li>If any other user logs in all your offline data will be deleted.</li>
              <li>Please make sure that you have enough credits before you start your sync.</li>
            </ul>
</small></strong></span>
        </DialogTitle>
        {loading ? <Loading /> : <>
          <DialogContent>
          
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick = { doSyncing }>
                Sync Your Data
            </Button>
          </DialogActions>
      </>}
    </Dialog>
  )
}
export default withSnackbar(SyncDataModal)
