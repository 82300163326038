import React, { Suspense, lazy, useEffect } from 'react';
import {Router, Route, Switch } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import RegisterThanks from './user-pages/RegisterThanks'
import { SnackbarProvider } from 'notistack';

const AdminLogin = lazy(() => import('./user-pages/AdminLogin'));
const AdminResetPwd = lazy(() => import('./user-pages/AdminResetPwd'));
const ChangePassword = lazy(() => import('./user-pages/ChangePassword'));
const ClinicRegisterationForm = lazy(() => import('./clinic/ClinicRegisterationForm'));


const AdminRoutes = (props) =>  {
  return (
    <Suspense fallback={<Spinner/>}>
      <SnackbarProvider>
        <Switch>
          <Route exact path="/" component={ AdminLogin } />
          <Route exact path="/admin-reset-pwd" component={ AdminResetPwd } />
          <Route exact path="/change_password" component={ ChangePassword } />
          <Route exact path="/clinic/register" component={ ClinicRegisterationForm } />
          <Route exact path="/clinic/register/thanks"  component={RegisterThanks} />
          
          
        </Switch>
      </SnackbarProvider>
    </Suspense>
  );
}

export default AdminRoutes;