import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        year : new Date().getFullYear()
    }
  }
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright WCABP © {this.state.year}. All rights reserved.</span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><a href="http://https://www.2webdesign.com" target="_blank">
                                <img src='/images/2web-logo.png'  alt="" title=""/> </a> </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;