import React, { Suspense, lazy, useEffect, useContext} from 'react';
import { Route, Switch, Link, useLocation, useHistory } from 'react-router-dom';
import Spinner from '../shared/Spinner';
import Producer from '../offlinePages/producers';
import AddProducer from '../offlinePages/producers/AddProducer';
import EditProducer from '../offlinePages/producers/EditProducer';
import ClinicDetails from '../offlinePages/clinic';
import PublishedEvaluation from '../offlinePages/evaluation/PublishedEvaluation';
import AddBull from '../offlinePages/bull/AddBull';
import Orders from '../offlinePages/order';
import EditClinic from '../offlinePages/clinic/EditClinic';
import { AuthContext } from "../App"
import { checkToken } from '../store/actions/auth';
import EditBull from '../offlinePages/bull/EditBull';
import SummaryReparts from '../offlinePages/reports/SummaryReparts';
const SingleOrder = lazy(() => import('../offlinePages/order/SingleOrder'));
const AdminInfoPracProd = lazy(() => import('./../tools/InfoPracProd'));
const AdminManageVets = lazy(() => import('./../tools/ManageVets'));
const AdminSearchReports = lazy(() => import('../reports/SearchReports'));
const AddNewEvaluation = lazy(() => import('../offlinePages/evaluationPages/AddNewEvaluation'));
const Evaluations = lazy(() => import('../clinic/Evaluations'));
const EditEvaluation = lazy(() => import('../offlinePages/evaluationPages/EditEvaluation'));

const Routers = (props) =>  {
  let history = useHistory()
  let context = useContext(AuthContext);
  let location = useLocation()

  useEffect(() => {
    if(context.state.token){
      // checkToken(context.state.token).then().catch(err => {
      //   if(err.response.status === 401){
      //     context.dispatch({type: "LOGOUT"})
      //     history.push("/")
      //   }
      // })
    }
    if(!context.state.isOnline && (!location.pathname.includes("offline") || location.pathname === "/")){
        history.push('/offline');
    }
  }, [])
  return (
    <Suspense fallback={<Spinner/>}>
      <Switch>
        <Route exact path="/offline" component={ClinicDetails} />
        <Route exact path="/offline/clinic/add-producer" component={AddProducer} />
        <Route exact path="/offline/clinic/producer/:id" component={EditProducer} />
        <Route exact path="/offline/producer/dashboard" component={Producer} />
        <Route exact path="/offline/evaluation/published" component={PublishedEvaluation}
         />
        <Route exact path="/offline/clinic/add-new-evaluation" component={ AddNewEvaluation} />
        <Route exact path="/offline/reports" component={AdminSearchReports} />
        <Route exact path="/offline/add-bull" component={AddBull} />
        <Route exact path="/offline/clinic/:producer_id/bull/:id" component={EditBull} />
        <Route exact path="/offline/orders" component={Orders} />
        <Route exact path="/offline/order/:id" component={SingleOrder} />
        <Route exact path="/offline/tools/pdfs" component={AdminInfoPracProd} />
        <Route exact path="/offline/manage-vet" component={AdminManageVets} />
        <Route exact path="/offline/clinic/edit" component={EditClinic} />
        <Route exact path="/offline/clinic/search-report" component={SummaryReparts} />
        <Route exact path="/offline/clinic/admin-bull-evaluations" component={ Evaluations} />
        <Route exact path="/offline/clinic/edit-evaluation" component={ EditEvaluation} />
      </Switch>
    </Suspense>
  );
}

export default Routers;