import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {IconButton} from '@material-ui/core'


const rows = [
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
  {sr_no:"01", name:'India', rfid_tag:'IN', date:"22/12/2020" },
];


export default function PublishUnpublished(props) {
  let color, backgroundColor,bordercolor;
  if(props.isPublished){
    backgroundColor="#def5db"
    color="#6eb542"
    bordercolor="#def5db"
  }else{
    backgroundColor="#fae0c9"
    color="#f68c31"
    bordercolor="#fae0c9"
  }
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{width:90}}>Sr. No.</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Name</TableCell>
              <TableCell align="left" style={{minWidth:100}}>RFID Tag</TableCell>
              <TableCell align="left" style={{minWidth:170}}>Evaluation Date</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell align="left">{row.sr_no}</TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {row.name}<br/>
                    <span style={{color:'#f68c31'}}>Red Angus</span>
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>{row.rfid_tag}</TableCell>
                  <TableCell align="left" style={{minWidth:170}}>
                    {row.date} <span style={
                      {color:color,
                      padding:5,
                      background:backgroundColor, 
                      borderRadius:5, 
                      border:`1px solid ${color}`}}>{props.isPublished? "UnPosted": "Posted"}</span>
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {
                      !props.isPublished && 
                      <IconButton color="default" aria-label="upload picture" component="span">
                        <EditIcon />
                      </IconButton>
                    }
                    <IconButton color="secondary" aria-label="upload picture" component="span">
                      <DeleteOutlineIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PictureAsPdfIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
