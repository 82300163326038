import React,  {useContext, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {useHistory,useLocation} from 'react-router-dom'
import {IconButton} from '@material-ui/core'
import DeleteBull from './DeleteBull';
import {deleteBullAction} from '../../store/actions/bull'
import {AuthContext} from '../../App'
import { useSnackbar } from 'notistack';
import {Link} from 'react-router-dom'
import { REQUEST_URL_PREFIX } from '../../Utils'
import {getclinicorder} from '../../store/actions/clinic';
import MaterialTable from 'material-table';

import AddIcon from '@material-ui/icons/Add';

import { getbreedlist, getallProvinces, getabnormalitylist, gettreatmentlist, deleteBullData } from '../../indexedDB';
import { ONLINE } from '../../store/actions/type';

export default function BullItem(props) {
  debugger
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  let {state} = useContext(AuthContext);
  const {isOnline} = state;
  const [open, setOpen] = React.useState(false)
  const [abnormality_list, setAbnormlityList] = useState(null);
  const [treatment_list, setTreatmentList] = useState(null);
  const [vet_list, setVetList] = useState(null);
  const [breed_list, setBreedList] = useState(null);
  const [producer_name, setProducerName] = useState();
  const [producer, setProducer] = useState();
  const producer_id = '';
  const clinic_name = '';
  const [provinceList, setProvinceList] = useState([]);
  const [prefixURL, setPrefixURL] = useState('');

  let history = useHistory()
  const [bull, setBull] = React.useState(null)
  const [bulls, setBulls] = React.useState([])
  let color, backgroundColor,bordercolor;
  if(props.isPublished){
    backgroundColor="#def5db"
    color="#6eb542"
    bordercolor="#def5db"
  }else{
    backgroundColor="#fae0c9"
    color="#f68c31"
    bordercolor="#fae0c9"
  }
  React.useEffect(() => {
    debugger
    if(!isOnline){
      setPrefixURL("/offline");
    }
    setBulls(props.bulls);
  }, [props.bulls]);

  const handleDialog = (bull) => {
    setBull(bull)
    handleBullDialog(true)
  }

  const handleBullDialog = (value) => {
    setOpen(value)
  }

  const deletePromise = () => {
    return isOnline
            ? deleteBullAction(state.token, bull.bull_id)
            : deleteBullData(bull.bull_id)
  }

  const deleteSubmit = () => {
    deletePromise().then((data) => {
      enqueueSnackbar(`Bull with id ${bull.bull_id} deleted successfully`,{variant:'success'});
      setOpen(false)
      const newBulls = bulls.filter((item) => item.bull_id !== bull.bull_id);
      setBulls(newBulls);
    }).catch(err => {
      console.log('err', err)
      enqueueSnackbar('something wrong while deleting bull',{variant:'warning'});
    })
  }

  const getProvincePromise = () => {
    return isOnline ? fetch(REQUEST_URL_PREFIX + 'provincelist', {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
        "Authorization": state.token
      }
    }).then(response => {
      if (response.status === 200) {
       } else {
         console.log("get failed")
       }
       return response.json();
    }
    ) : getallProvinces()
  }

  useEffect(() => {
    new Promise((resolve)=>{
      getProvincePromise().then(result=>{
          isOnline ? setProvinceList(result.provinceList) : setProvinceList(result.data.provinceList)
          }).catch((error)=>{
              console.log(error);
          })
      })

  const getBreedListPromise = () => {
    return isOnline ? fetch(REQUEST_URL_PREFIX + 'breedlist', {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
        "Authorization": state.token
      },
    }).then(response => {
        if (response.status === 200) {
          console.log("get successfully")
        } else {
          console.log("get failed")
        }
        return response.json()
      }) : getbreedlist()
  }

  new Promise(() => {
    getBreedListPromise().then((content) => {
      isOnline ? setBreedList(content.breedList) : setBreedList(content.data.breedList)
      }).catch((error)=>{
          console.log(error);
      })
  })

  const getAbnormilityLit = () => {
    return isOnline ? fetch(REQUEST_URL_PREFIX + 'abnormalitylist', {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
        "Authorization": state.token
      },
    }).then(response => {
      console.log(response);
      if (response.status === 200) {
        console.log("get successfully")
      } else {
        console.log("get failed")
      }
      return response.json()
    }) : getabnormalitylist()
  }

    new Promise(() => {
      getAbnormilityLit().then((content) => {
            isOnline ? setAbnormlityList(content.abnormalityList) : setAbnormlityList(content.data.abnormalityList);
          })
            .catch((error)=>{
              console.log(error);
          })
      })

    const getTreatmentPromise = () => {
      return isOnline ? fetch(REQUEST_URL_PREFIX + 'treatmentlist', {
        method: 'GET',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-api-key": "6VSFKcvXN9t9$s7?vr2V*rZ8JpttU3",
          "Authorization": state.token
        },
      }).then(response => {
        if (response.status === 200) {
          console.log("get successfully")
        } else {
          console.log("get failed")
        }
        return response.json()
      }) : gettreatmentlist();
    }

      new Promise(() => {
        getTreatmentPromise().then((content) => {
            isOnline ? setTreatmentList(content.treatmentList) : setTreatmentList(content.data.treatmentList);
          })
            .catch((error)=>{
              console.log(error);
          })
      })
      setProducer(props.producer)

    },[props.producer])

  const AddNewEvaluation  = (row) => {
    debugger
    // getclinicorder(state.token, producer.clinic_id).then(({data}) => {
    //   if(data.count > 0) {
        history.push(`${prefixURL}/clinic/add-new-evaluation`,
          {
            clinicName: '',
            clinic_id: producer.clinic_id,

            producerName: producer,
            clinicId: producer.clinic_id,
            producerId: producer_id,
            bullId: row.bull_id,
            tag: row.rfid_tag,
            tag_type:row.tag_type,
            name: row.name,
            brandinput: row.brand,
            birthDate: new Date(row.birth_date*1000).toISOString().slice(0,10),
            breed: row.breed_id,
            breedothers: row.breed_other,
            producerName:props.producer.name
          })
    //   } else {
    //     let msg;
    //     if(state.isClinic) {
    //       msg = 'Not enough credits, please buy some to create new evaluation';
    //     } else {
    //       msg = 'Not enough credits, please ask the clinic owner to buy some more credits to create new evaluations';
    //     }
    //     enqueueSnackbar(`${msg}`,{variant:'warning'});
    //   }
    // }).catch(err => {
    //   console.log(err)
    //   enqueueSnackbar('something wrong while, please reload the page',{variant:'warning'});
    // })
  }

  const editBull = (event, rowData) => {
    debugger;
    history.push(`${prefixURL}/clinic/${props.producer.producer_id}/bull/${rowData.bull_id}`, {

      clinic_id:props.producer.clinic_id,
      producer:props.producer
    })
  }

  return (
    <>
       <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
        <MaterialTable
        title='Bulls Information'
        columns={[
          { title: 'Sr.No.', field: 'bull_id' },
          { title: 'Name', field: 'name'},
          { title: 'Method of ID', field: 'tag_type' },
          { title: 'Bull ID', field: 'brand' },
          { title: 'RFID Tag', field: 'rfid_tag' },
          { title: 'Breed', field: 'breed',
            render: rowData => rowData.breed_name == "Other" ? rowData.breed+': '+rowData.breed_other : rowData.breed,
          },
          // { title: 'Tag Type (Method of Identification)', field: 'tag_type' },
          { title: 'Published Evaluations', field: 'published_cnt',
             render: rowData => <Link to=
                {{
                    pathname:`${prefixURL}/clinic/admin-bull-evaluations`,
                    state: {
                        id: rowData.bull_id,
                        published: 1,
                        clinicId: producer.clinicId,
                        name: rowData.name,
                        tag: rowData.rfid_tag,
                        tag_type: rowData.tag_type,
                        brandinput: rowData.brand,
                        producerId: producer_id,
                        breed: rowData.breed_id,
                        breedother: rowData.breed_other,
                        birthDate: new Date(rowData.birth_date*1000).toISOString().slice(0,10),
                        clinicName: clinic_name,
                        breedList: breed_list,
                        abnormalityList: abnormality_list,
                        treatmentList: treatment_list,
                        vetList: vet_list,
                        producerName:props.producer.name
                }}}><u>{rowData.published_cnt}</u></Link> },
          { title: 'UnPublished Evaluations', field: 'unpublished_cnt',
            render: rowData => {
             return (
                <Link to=
                {{
                    pathname:`${prefixURL}/clinic/admin-bull-evaluations`,
                    state: {
                        id: rowData.bull_id,
                        published: 0,
                        clinicId: producer.clinicId,
                        name: rowData.name,
                        tag: rowData.rfid_tag,
                        brandinput: rowData.brand,
                        producerId: producer_id,
                        breed: rowData.breed_id,
                        breedother: rowData.breed_other,
                        birthDate: new Date(rowData.birth_date*1000).toISOString().slice(0,10),
                        clinicName: clinic_name,
                        breedList: breed_list,
                        abnormalityList: abnormality_list,
                        treatmentList: treatment_list,
                        vetList: vet_list,
                        tag_type: rowData.tag_type,
                        producerName:props.producer.name
                }}}><u>{rowData.unpublished_cnt}</u>
            </Link>
              )
            }
             },
          // { title: 'Breed', field: 'breed_id', hidden: true },
          { title: 'Birth Date', field: 'birth_date', hidden: true },
          { title: 'Other Breed', field: 'breed_other', hidden: true },
        ]}

        data={bulls}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Evaluation',

            onClick: (event, rowData) => AddNewEvaluation(rowData)

          },
          {
            icon: 'edit',
            tooltip: 'Edit Bull',
            onClick: (event, rowData) => editBull(event, rowData)


          },
          {
            icon: 'delete',
            tooltip: isOnline ? 'Delete Bull' : 'Cannot delete bull in offline mode',
            disabled : isOnline ? false : true,
            onClick:(event, rowData) => {
                setOpen(true);
                setBull(rowData)
                  // setOpenConfirmBull(true);
                  // setBullId(rowData.bull_id);
                  // setBullName(rowData.name);
                }
            },

          {
            icon: 'add',
            tooltip: 'Add Bulls',
            isFreeAction: true,
            onClick: () => history.push(`${prefixURL}/add-bull`, {
                producerId: producer.producer_id,
                producer:producer,
                clinic_id:producer.clinic_id
            } )
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          headerStyle: {
            backgroundColor: 'transperant',
            color: '#23263d'
          }
        }}/>
        {/* {openConfirmBull?
          <ConfirmDialog id={bull_id} name={bull_name} open={openConfirmBull} setopen={setOpenConfirmBull} fetchData={fetchData} /> : null
        } */}
          <DeleteBull
            handleBullDialog={handleBullDialog}
            open={open}
            deleteSubmit={deleteSubmit}
            bull={bull}
          />

        </div>
    </>
  );
}
