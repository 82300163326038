import React from 'react';

function Footer(props) {
    return (
        <div style={{color: 'red', fontWeight: '800', fontSize: '20px', margin: '0 auto 20px'}}>
            *Any functionalities that are greyed out/disabled will not work in the Offline Mode.
        </div>
    )
}

export default Footer;