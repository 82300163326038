import React, { useState, useEffect} from 'react';
import { Button, Form, Col} from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

export default function registerThanks(props) {

    return (
      <>
        <div className="login-wrap">
          <div className="d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-7 mx-auto login-form">
                <div className="auth-form-light text-left">
                  <div className="brand-logo mb-0">
                    <center>
                      <img src="/images/login-logo.png" alt="WCABP" title="WCABP" />
                    </center>
                  </div>
                  <div className="form-section py-4 px-4 px-sm-5">
                    <center><AccountCircleOutlinedIcon className="icon-user" /></center>
                    <span className="thanku-word">
                      <center className="word">
                        Thank you for registering with us!
                      </center>
                      <center>
                        You will get an email notification when your account is approved by the Admin.You will be able to login after approval.
                      </center>
                    </span>
                  </div>
                  <div className="form-section py-4 px-4 px-sm-5">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridState">
                        <Button onClick={() => props.history.push('/')} className="back-to-home btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">BACK TO LOGIN</Button>
                      </Form.Group>
                    </Form.Row>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </>
      
    )
}
