import React, {useRef,useState, useContext,useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom'
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator'
import MuiPhoneNumber from "material-ui-phone-number";
import {Button, makeStyles, Grid,} from '@material-ui/core'
import {getAllProvinces} from '../../store/actions/clinic'
import {createProducer} from '../../store/actions/producer'
import {withSnackbar} from 'notistack'
import { AuthContext } from "../../App"
import {Form} from 'react-bootstrap'
import { getallProvinces, putproducerdetails, addProducerDetails } from '../../indexedDB';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  margin: {
    margin: theme.spacing(4),
    width: '20ch',
  },
}));

const AddProducer = (props) => {
  const isValidZip =  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  let history = useHistory()
  let {state} = useContext(AuthContext)
  const [provinces, setProvinces] = useState([])
  const [prefixURL, sePrefixURL] = useState('');
  const [producer, setProducer] = useState({
    name:"",
    clinic_id:state.user.clinicId,
    fax:"",
    phone:"",
    postal_code:"",
    email:"",
    province:"",
    city:"",
    address:""
  })
  const formRef = useRef(null);
  const classes = useStyles();
  function urlList() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Producers </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/${prefixURL}`}>
                  { state.isOnline && <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" /> }
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Add Producer</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name=='postal_code') {
  const formattedPostalCode = event.target.value.replace(/[^0-9a-zA-Z]/gi, "").toUpperCase();
  if (formattedPostalCode.length > 3) {
    value = formattedPostalCode.slice(0, 3) + " " + formattedPostalCode.slice(3);
  } else {
    value = formattedPostalCode;
  }
}


    console.log("name, value",name, value )
    setProducer(prevForm => ({
      ...prevForm,
      [name]:value
    }))
  }

  const getAllProvincePromise = () => {
    const { isOnline } = state;
    return isOnline ? getAllProvinces() : getallProvinces();
  }

  useEffect(() => {
    getAllProvincePromise().then(({data}) => {
      if(!state.isOnline){
        sePrefixURL('/offline');
      }
      setProvinces(data.provinceList)
    }).catch(err => {
      console.log("err", err)
    })
  }, [])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidZipcode', (value) => {
      if (isValidZip.test(value)!==true) {
          return false;
      }
      return true;
    });
  }, []);

  const createProducerPromise = (tempProducer, token) => {
    const { isOnline } = state;
    if(isOnline){
      return createProducer(tempProducer, token);
    } else {
      tempProducer = { ...tempProducer, producer_id: (+new Date()).toString() };
      return addProducerDetails(tempProducer.producer_id, tempProducer, state.user.userId  )
    }
  }

  const onHandleSubmit = () => {
    let tempProducer = {clinicId:state.user.clinicId,clinic_id:state.user.clinicId, ...producer}
    createProducerPromise(tempProducer, state.token).then(() => {
      history.push(`${prefixURL}`)
      props.enqueueSnackbar('Added Producer Successfully',{variant:"success"});
    }).catch(err => {
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
      console.log("err")
    })
  }
  return (
    <>
      {urlList()}
      <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer" style={{padding:0}}>
	      <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
          <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Creating New Producer</strong></span>
		    </div>
		    <div className="add-new-bull">
          <ValidatorForm ref={formRef} onSubmit={onHandleSubmit} className={classes.root} noValidate autoComplete="off">
            <div className={classes.root} style={{padding:'20px'}}>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs sm={3}>
                    <TextValidator
                      className="addProdColThree"
                      required id="name"
                      label="Name"
                      name="name"
                      value={producer.name}
                      onChange={(event) => onChangeHandler(event)}
                      variant="outlined"
                      validators={['required', 'maxStringLength:32']}
                      errorMessages={['this field is required', 'the max length is 32']}
                    />
                  </Grid>
                  <Grid item xs sm={3}>
                    <TextValidator
                      className="addProdColThree"
                      required id="email"
                      label="Email"
                      name="email"
                      value={producer.email}
                      onChange={(event) => onChangeHandler(event)}
                      variant="outlined"
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email address is not valid']}
                    />
                  </Grid>
                  <Grid item xs sm={3}>
                    <MuiPhoneNumber className="addProdColThree" required
                      onlyCountries	={['ca','us']}
                      name="phone"
                      onChange={(event) => setProducer({...producer, phone:event})}
                      label="Phone"
                      variant="outlined"
                      data-cy="user-phone"
                      defaultCountry={"ca"}
                    />
                  </Grid>

                  <Grid item xs sm={3}>
                    <MuiPhoneNumber
                      className="addProdColTwo"
                      onlyCountries	={['ca','us']}
                      disableDropdown="true"
                      name="fax"
                      label="Fax"
                      onChange={(event) => setProducer({...producer, fax:event})}
                      variant="outlined"
                      data-cy="user-phone"
                      defaultCountry={"ca"}
                    />

                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs sm={3}>
                    <TextValidator
                      className="addProdColThree"
                      required id="streetaddr"
                      label="Location"
                      variant="outlined"
                      name="address"
                      value={producer.address}
                      onChange={(event) => onChangeHandler(event)}
                      validators={['required', 'maxStringLength:100']}
                      errorMessages={['this field is required', 'the max length is 100']}
                    />
                  </Grid>

                  <Grid item xs sm={3}>
                    <TextValidator
                      className="addProdColThree"
                      required id="city"
                      label="City"
                      name="city"
                      value={producer.city}
                      onChange={(event) => onChangeHandler(event)}
                      variant="outlined"
                      validators={['required', 'maxStringLength:32']}
                      errorMessages={['this field is required', 'the max length is 32']}
                    />
                  </Grid>
                  <Grid item xs sm={3}>
                    <SelectValidator
                      className="addProdColThree"
                      required
                      variant="outlined"
                      label="Province"
                      name="province"
                      onChange={(event)=>onChangeHandler(event)}
                      value={producer.province}
                      placeholder="SELECT Province..."
                      validators={['required']}
                      errorMessages={['this field is required']}
                    >
                        <option>Province*...</option>
                        {provinces.map(province => (
                          <option value={province.province_name} key={province.provinceId}>{province.province_name}</option>
                        ))}
                      </SelectValidator>
                  </Grid>
                  <Grid item xs sm={3}>
                    <TextValidator
                      className="addProdColTwo"
                      id="postcal-code"
                      onChange={(event) => onChangeHandler(event)}
                      label="Postal Code"
                      name="postal_code"
                      value={producer.postal_code}
                      required variant="outlined"
                      validators={['required','isValidZipcode']}
                      errorMessages={['this field is required', 'This Postal code is not valid.']}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs sm={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      // onClick={onHandleSubmit}
                    >
                      Add
                    </Button>
                  </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Grid item xs sm={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={() => props.history.push(`${prefixURL}`)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>

            </div>
          </ValidatorForm>
		    </div>
      </div>
    </>


  );
}

export default withSnackbar(AddProducer)
