import axios from './../axios'
import key from './key'

export const getClinicVets = async (token) =>{
  return await axios.get('/clinicvets', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const createVet = async (token) =>{
  return await axios.get('/allBreeds', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllVetList = async (token) =>{
  return await axios.get('/vetslist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getAllBreed = async (token) =>{
  return await axios.get('/breedlist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getCurrentVet = async (token, vetId) => {
  return await axios.get(`/vetsdetails/${vetId}`, {
    headers : {
      "x-api-key" : key,
      "Authorization" : token
    }
  });
}

// export const getCurrentVet = async (token, vetId) => {
//   return await axios.get(`/vetsdetails/${vetId}`, {
//     headers : {
//       "x-api-key" : key,
//       "Authorization" : token
//     }
//   });
// }

export const checkVetEmail = async(token, data) => {
  return await axios.post('/vet/checkemail/', data, {
    headers:{
      "Authorization" : token,
      "x-api-key": key,
    }
  })
}

export const getEvaluationListHandler = async(bull_id, published, token) => {
  // REQUEST_URL_PREFIX + 'evaluationlistbyBullid'
  const data = {
      bull_id: bull_id,
      is_posted: published
  };
  return await axios.post('/evaluationlistbyBullid', data, {
    headers:{
      "Authorization" : token,
      "x-api-key": key,
    }
  })
}