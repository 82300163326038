import React, { Suspense, lazy, useEffect, useContext} from 'react';
import { Route, Switch } from 'react-router-dom';
import Spinner from '../shared/Spinner';
import Producer from '../pages/producers';
import AddProducer from '../pages/producers/AddProducer';
import EditProducer from '../pages/producers/EditProducer';
import ClinicDetails from '../pages/clinic';
import PublishedEvaluation from '../pages/evaluation/PublishedEvaluation';
import AddBull from '../pages/bull/AddBull';
import Orders from '../pages/order';
import EditClinic from '../pages/clinic/EditClinic';
import { AuthContext } from "../App"
import { checkToken } from '../store/actions/auth';
import {useHistory} from 'react-router-dom'
import EditBull from '../pages/bull/EditBull';
import SummaryReparts from '../pages/reports/SummaryReparts';
import VerifyEmail from '../user-pages/VerifyEmail';
import Refetch from '../user-pages/Refetch.js';
const SingleOrder = lazy(() => import('../pages/order/SingleOrder'));
const AdminInfoPracProd = lazy(() => import('./../tools/InfoPracProd'));
const AdminManageVets = lazy(() => import('./../tools/ManageVets'));
const AdminSearchReports = lazy(() => import('../reports/SearchReports'));
const AddNewEvaluation = lazy(() => import('./../clinic/AddNewEvaluation'));
const Evaluations = lazy(() => import('../clinic/Evaluations'));
const EditEvaluation = lazy(() => import('../clinic/EditEvaluation'));

const Routers = (props) =>  {
  let history = useHistory()
  let context = useContext(AuthContext)

  useEffect(() => {
    if(context.state.token){
      checkToken(context.state.token).then().catch(err => {
        if(err.response.status === 401){
          context.dispatch({type: "LOGOUT"})
          history.push("/")
        }
      })
    }
  }, [])
  return (
    <Suspense fallback={<Spinner/>}>
      <Switch>
        <Route exact path="/" component={ClinicDetails} />
        <Route exact path="/clinic/add-producer" component={AddProducer} />
        <Route exact path="/clinic/producer/:id" component={EditProducer} />
        <Route exact path="/producer/dashboard" component={Producer} />
        <Route exact path="/evaluation/published" component={PublishedEvaluation}
         />
        <Route exact path="/clinic/add-new-evaluation" component={ AddNewEvaluation} />
        <Route exact path="/reports" component={AdminSearchReports} />
        <Route exact path="/add-bull" component={AddBull} />
        <Route exact path="/clinic/:producer_id/bull/:id" component={EditBull} />
        <Route exact path="/orders" component={Orders} />
        <Route exact path="/order/:id" component={SingleOrder} />
        <Route exact path="/tools/pdfs" component={AdminInfoPracProd} />
        <Route exact path="/manage-vet" component={AdminManageVets} />
        <Route exact path="/clinic/edit" component={EditClinic} />
        <Route exact path="/clinic/search-report" component={SummaryReparts} />
        <Route exact path="/clinic/admin-bull-evaluations" component={ Evaluations} />
        <Route exact path="/clinic/edit-evaluation" component={ EditEvaluation} />
        <Route exact path="/clinic/verifyemail"  component={VerifyEmail} />
        <Route exact path="/clinic/refetch"  component={Refetch} />
      </Switch>
    </Suspense>
  );
}

export default Routers;