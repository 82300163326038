import axios from './../axios'
import key from './key'
import clinic from '../../pages/clinic'

export const createClinic = async (resisterForm) =>{
  return await axios.post('/register/clinic/', resisterForm, {
    headers:{
      "x-api-key": key,
    }
  })
}

export const getAllProvinces = async () =>{
  return await axios.get('/provincelist', {
    headers:{
      "x-api-key": key,
    }
  })
}

export const getAbnormalityTypelist = async (token) =>{
  return await axios.get('/abnormalityTypelist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getClinic = async (token) =>{
  return await axios.get('/clinic/owninformation', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const updateClinic = async (clinic,token) =>{
  return await axios.post('updateclinic', clinic,{
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const checkClinicEmail = async (data) =>{
  return await axios.post('/clinic/checkemail/', data, {
    headers:{
      "x-api-key": key,
    }
  })
}

export const getGSTRateForProvince = async(token) => {
  return await axios.get('/getgstforclinic/', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getclinicorder = async(token, cid) => {
  return await axios.post('/clinicordercount', {clinicId:cid}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const sendMail = async(token, email, subject) => {
  return await axios.post('/sendmail', {clinicId:subject}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getClinicList = async (token) =>{
  return await axios.get('/cliniclist', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}


export const getProducerBasicsWithBullInfo = async (token, producer_id) => {
  return await axios.get('/producerbasicwithbullinfo/' + producer_id, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  });
}

export const syncProducerData = async(token, data) => {
  return await axios.post('/sync/producers', {...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token,
    }
  })
}

export const syncBullData = async(token, data) => {
  return await axios.post('/sync/bulls', {...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const syncEvaluationData = async(token, data) => {
  return await axios.post('/sync/evaluations', {...data}, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const getBulkProducerData = async (token) => {
  return await axios.get('/sync/getproducers', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  });
}

export const getBulkBullData = async (token) => {
  return await axios.get('/sync/getbulls', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  });
}

export const getBulkEvaluationData = async (token) => {
  return await axios.get('/sync/getevaluations', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  });
}