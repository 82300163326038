import clinicordercountDB from './DB/clinicOrderCount';
import {getEvaluationListHandler} from '../app/store/actions/vet';
import { singleEvaluation } from '../app/store/actions/evaluation';

export const putabnormalitylist = (data) => {
  const abnormalityList = [];
  for(let key in data){
    let tempObj = {
      id: key,
      value: data[key]
    };
    abnormalityList.push(tempObj);
  }
  clinicordercountDB.abnormalitylist.bulkPut([...abnormalityList]).then(() => {
  }).catch((err) => {
    alert("Not getting data 888", err)
  })
}

export const getabnormalitylist = () => {
  return clinicordercountDB.table("abnormalitylist").toArray().then((data) => {
      let abnormalData = {};
      data.forEach((abData) => {
        abnormalData[[abData['id']]] = abData['value'];
      })
      return Promise.resolve({data: {abnormalityList: abnormalData}});
  }).catch((err) => {
    alert("Not getting data 222", err)
  })
}

export const putallProvinces = (data) => {
  clinicordercountDB.allProvinces.bulkPut([...data]).then(() => {
  }).catch((err) => {
    alert("Not getting data 333", err)
  })
}

export const getallProvinces = () => {
  return clinicordercountDB.table("allProvinces").toArray().then((data) => {
      return Promise.resolve({data: {provinceList: data}});
  }).catch((err) => {
    alert("Not getting data 222", err)
  })
}

export const putbreedlist = (data) => {
  clinicordercountDB.breedlist.bulkPut([...data]).then(() => {
  }).catch((err) => {
    alert("Not getting data 777", err)
  })
}

export const getbreedlist = () => {
  debugger
  return clinicordercountDB.table("breedlist").toArray().then((data) => {
      return Promise.resolve({data: {breedList: data}});
  }).catch((err) => {
    alert("Not getting data 222", err)
  })
}

export const putClinicordercount = (version, data) => {
  clinicordercountDB.clinicordercount.put({...data}).then(() => {
    }).catch((err) => {
    })
}

export const getClinicordercount = () => {
  return clinicordercountDB.table("clinicordercount").toArray().then((data) => {
      return Promise.resolve({data: data[0]});
  }).catch((err) => {
  })
}

export const putOwninformation = (data) => {
  clinicordercountDB.ownInformation.put({...data}).then(() => {
  }).catch((err) => {
  })
}

export const getOwninformation = () => {
  return clinicordercountDB.table("ownInformation").toArray().then((data) => {
      return Promise.resolve({data: {clinicDetail: data[0]}});
  }).catch((err) => {
    console.log(err)
    alert("Not getting own information", err)
  })
}

export const updateOwninformation = (key, changes) => {
    return clinicordercountDB.ownInformation.update(key, changes).then((data) => {
    }).catch((err) => {
    })
}

export const puttreatmentlist = (data) => {
  clinicordercountDB.treatmentlist.bulkPut([...data]).then(() => {
  }).catch((err) => {
  })
}

export const gettreatmentlist = () => {
  return clinicordercountDB.table("treatmentlist").toArray().then((data) => {
      return Promise.resolve({data: {treatmentList: data}});
  }).catch((err) => {
  })
}

export const putuserData = (data) => {
  clinicordercountDB.userData.put({...data}).then((data) => {
  }).catch((err) => {
  })
}

export const getuserData = () => {
  return clinicordercountDB.table("userData").toArray().then((data) => {
      return data ? Promise.resolve({data: data[0]}) : Promise.resolve('Data Not Found');
  }).catch((err) => {
  })
}

export const putvetList = (data) => {
  clinicordercountDB.vetList.bulkPut([...data]).then(() => {
  }).catch((err) => {
  })
}

export const getvetList = () => {
  return clinicordercountDB.table("vetList").toArray().then((data) => {
      return Promise.resolve({data: {vets: data}});
  }).catch((err) => {
  })
}

export const putvetsdetails = (data) => {
  clinicordercountDB.vetsdetails.put({...data}).then(() => {
  }).catch((err) => {
  })
}

export const getvetsdetails = () => {
  return clinicordercountDB.table("vetsdetails").toArray().then((data) => {
      return Promise.resolve({data: {VetsDetail: data[0]}});
  }).catch((err) => {
  })
}

export const putBulkProducers = (data) => {
  const producerData = {id: 1, value: data};
  clinicordercountDB.producers.put({...producerData}).then(() => {
  }).catch((err) => {
  })
}

export const getBulkProducers = (producerId) => {
  return clinicordercountDB.producers.get(producerId, (data) => {
    return data.value
            ? Promise.resolve({data: {producercList: data.value}})
            : Promise.reject("Data not found");
  });
}

export const putBulkBulls = (data) => {
  const recordData = {id: 1, value: data};
  clinicordercountDB.bulls.put({...recordData}).then(() => {
  }).catch((err) => {
  })
}

export const getBulkBulls = (bullID) => {
  debugger
  return clinicordercountDB.bulls.get(bullID, (data) => {
    return data.value
    ? Promise.resolve({data: {bullList: data.value}})
    : Promise.reject("Data not found");
  });
}

export const putBulkEvaluations = (data) => {
  const recordData = {id: 1, value: data};
  clinicordercountDB.evaluations.put({...recordData}).then(() => {
  }).catch((err) => {
  })
}

export const getBulkEvaluations = (recordID) => {
  return clinicordercountDB.evaluations.get(recordID, (data) => {
    return data.value
    ? Promise.resolve({data: data.value})
    : Promise.reject("Data not found");
  });
}

export const putRecord = ({typeId, data, id, type}) => {
  const recordData = {data, id, typeId, type};
  clinicordercountDB.record.put({...recordData}).then(() => {
  }).catch((err) => {
  })
}

export const getRecord = (recordID) => {
  return clinicordercountDB.record.get(recordID, (data) => {
    return Promise.resolve({data});
  });
}

//FILTERED DATA

//Producers function
export const getProducerDetails = async(producerId) => {
  const {data: { producercList } } = await getBulkProducers(1);
  const filteredProducerData = producercList.filter((producer) => producer.producer_id === producerId);
  return Promise.resolve({data: {producerDetail: filteredProducerData[0]} });
}

export const updateProducerDetails = async(producerId, updatedData, userId) => {
  const {data: { producercList } } = await getBulkProducers(1);
  const findIndex = producercList.findIndex((producer) => producer.producer_id === producerId);
  producercList.splice(findIndex, 1, updatedData);
  putBulkProducers(producercList);
  putRecord({typeId: `${producerId}_pr_${userId}`, data: updatedData, id: producerId, type: 'producer'});
  return Promise.resolve("Data has updated");
}

export const addProducerDetails = async(producerId, addedData, userId) => {
  const {data: { producercList } } = await getBulkProducers(1);
  producercList.unshift(addedData);
  putBulkProducers(producercList);
  putRecord({typeId: `${producerId}_pr_${userId}`, data: addedData, id: producerId, type: 'producer'});
  return Promise.resolve({status: 200})
}


// Bull Functions
export const getBullDataWithProducerId = async(producerId) => {
  const {data: { bullList } } = await getBulkBulls(1);
  const filteredBullList = bullList.filter((bullData) => bullData.producer_id === producerId);
  return Promise.resolve({data: {bull_list: filteredBullList} });
}

export const getBullDetails = async(bullId) => {
  const {data: { bullList } } = await getBulkBulls(1);
  const filteredBullList = bullList.filter((bullData) => bullData.bull_id === bullId);
  return Promise.resolve({data: {bull_details: filteredBullList[0]} });
}

export const updateBullDetails = async(bullId, updatedData, userId) => {
  const {data: { bullList } } = await getBulkBulls(1);
  const findIndex = bullList.findIndex((bull) => bull.bull_id === bullId);
  bullList.splice(findIndex, 1, updatedData);
  putBulkBulls(bullList);
  putRecord({typeId: `${bullId}_bu_${userId}`, data: updatedData, id: bullId, type: 'bull'});
  return Promise.resolve("Data has updated");
}

export const addBullDetails = async(bullId, addedData, userId) => {
  const {data: { bullList } } = await getBulkBulls(1);
  bullList.unshift(addedData);
  putBulkBulls(bullList);
  putRecord({typeId: `${bullId}_bu_${userId}`, data: addedData, id: bullId, type: 'bull'});
  return Promise.resolve({status: 200})
}

// Evaluation Functions
export const getEvaluationDataWithBullId = async(bullId, isPublished) => {
  const {data} = await getBulkEvaluations(1);
  const filteredEvalList = data.filter((evaluation) => (evaluation.bull_id === bullId && evaluation.is_posted == isPublished));
  return Promise.resolve({evaluationList: filteredEvalList});
}

export const getEvaluationDetails = async(evaluationId) => {
  const {data} = await getBulkEvaluations(1);
  const filteredEvalList = data.filter((evaluation) => evaluation.evaluation_id === evaluationId);
  return Promise.resolve({data: {evaluation: filteredEvalList[0]} });
}

export const updateEvaluationDetails = async(evaluationId, updatedData, userId) => {
  const {data} = await getBulkEvaluations(1);
  const findIndex = data.findIndex((bull) => bull.evaluation_id === evaluationId);
  data.splice(findIndex, 1, updatedData);
  putBulkEvaluations(data);
  await _updateBullWithEvaluationData(updatedData.bull_id, updatedData.is_posted, userId, true);
  putRecord({typeId: `${evaluationId}_ev_${userId}`, data: updatedData, id: evaluationId, type: 'evaluation'});
}

export const addEvaluationDetails = async(evaluationId, addedData, userId) => {
  const {data} = await getBulkEvaluations(1);
  data.unshift(addedData);
  putBulkEvaluations(data);
  await _updateBullWithEvaluationData(addedData.bull_id, addedData.is_posted, userId, false);
  putRecord({typeId: `${evaluationId}_ev_${userId}`, data: addedData, id: evaluationId, type: 'evaluation'});
  return Promise.resolve({status: 200})
}

export const getBullTreatmentList = async(bullId) => {
  const {data} = await getBulkEvaluations(1);
  const filteredEvalList = data.filter((evaluation) => (evaluation.bull_id === bullId && !(+evaluation.is_posted)));


  if (filteredEvalList.length === 0) {
    // Return an empty treatments array if no matching evaluations are found
    return Promise.resolve({ data: { treatments: [] } });
  } else {
    // Return the treatments array from the first matching evaluation
    return Promise.resolve({data: {treatments: filteredEvalList[0].treatments || []}});
  }
  // return Promise.resolve({data: {treatments: filteredEvalList[0].treatments || []}});
}

const _updateBullWithEvaluationData = async(bullId, isPublished, userId, isUpdated) => {
  const { data: {bull_details} } = await getBullDetails(bullId);
  if(isUpdated){
    if(isPublished){
      bull_details.unpublished_cnt = +bull_details.unpublished_cnt - 1;
      bull_details.published_cnt = +bull_details.published_cnt + 1;
    }
  } else {
    if(isPublished){
      bull_details.published_cnt = +bull_details.published_cnt + 1;
    } else {
      bull_details.unpublished_cnt = +bull_details.unpublished_cnt + 1;
    }
  }
  await updateBullDetails(bullId, bull_details, userId);
  return Promise.resolve("Data is updated");
}


//Records functions

export const getUpdatedRecord = (resourceType) => {
  return clinicordercountDB.record.where("type").equalsIgnoreCase(resourceType).toArray()
}

export const deleteProducerFromRecord = (producerId, userId) => {
  const ID = `${producerId}_pr_${userId}`
  return clinicordercountDB.record.delete(ID);
}

export const deleteBullFromRecord = (bullId, userId) => {
  const ID = `${bullId}_bu_${userId}`
  return clinicordercountDB.record.delete(ID);
}

export const deleteEvaluationFromRecord = (evaluationId, userId) => {
  const ID = `${evaluationId}_ev_${userId}`;
  return clinicordercountDB.record.delete(ID);
}


// CHANGE IT REMOVE IT

//     let producerBulkData = {
//       id: 1,
//       value: data
//     }
//     clinicordercountDB.clinicProducers.put(producerBulkData).then(() => {
//     }).catch((err) => {
//       // alert("Not getting data 111", err)
//     })
// }

export const getVetProducers = () => {
  return clinicordercountDB.table("clinicProducers").toArray().then((data) => {
    return Promise.resolve({data: {producercList: data}});
  }).catch((err) => {
    alert("Not getting data 222", err)
  })
}

export const getclinicProducers = () => {
    return clinicordercountDB.table("producerdetails").toArray().then((data) => {
        return Promise.resolve({data: {producercList: data}});
    }).catch((err) => {
      alert("Not getting data 222", err)
    })
}

export const deleteclinicProducers = (producerId) => {
  deleteproducerdetails(producerId);
  return clinicordercountDB.clinicProducers.delete(producerId);
}

export const putproducerbasicwithbullinfo = (data, addEvaluation, authToken) => {
  clinicordercountDB.producerbasicwithbullinfo.put(data).then((data) => {
  }).catch((err) => {
    alert("Not getting data 666", err)
  });
  data.bull_list.forEach(async(data) => {
    putBullList(data);
    if(addEvaluation){
      const unpublishedData = await getEvaluationListHandler(data.bull_id, 0, authToken)
      unpublishedData.data.evaluationList && putEvaluationList(unpublishedData.data.evaluationList, data.bull_id, 0, authToken);
      const publishedData = await getEvaluationListHandler(data.bull_id, 1, authToken)
      publishedData.data.evaluationList && putEvaluationList(publishedData.data.evaluationList, data.bull_id, 1, authToken);
    }
  })
}

export const getproducerbasicwithbullinfo = (producerId, newBullId) => {
  return clinicordercountDB.producerbasicwithbullinfo.get(producerId, (data) => {
    if(newBullId) data.bull_list.push({bull_id: newBullId});
    return Promise.all(data.bull_list.map(async(bullData) => {
      try{
        bullData = await getBullData(bullData.bull_id);
      } catch(err){
        return ;
      }
      return bullData;
    })).then((bullList) => {
      data.bull_list = bullList.filter((bull) => bull);
      return data ? Promise.resolve({data: {producerDetail: data}}) : Promise.reject("Data Not Found");
    })
  });
}

export const updateproducerbasicwithbullinfo = (key, changes) => {
  return clinicordercountDB.producerbasicwithbullinfo.update(key, changes).then((data) => {});
}

export const putBullList = (data) => {
  clinicordercountDB.bullList.put(data).then(() => {
    return Promise.resolve({data: {}})
  }).catch((err) => {
    alert("Not getting data 666AAA", err);
  });
}

export const getBullData = (bullId) => {
  return clinicordercountDB.bullList.get(bullId, (data) => {
    return data ? Promise.resolve(data) : Promise.reject("Data Not Found");
  });
}

export const getBullListWithProducerId = (producerId) => {
  return clinicordercountDB.bullList.where("producer_id").equals(producerId).toArray()
}

export const createBullData = (data, producerId) => {
  return clinicordercountDB.bullList.put(data).then(async(data) => {
    const {data: {producerDetail}} = await getproducerbasicwithbullinfo(producerId, data)
    updateproducerbasicwithbullinfo(producerId, producerDetail);
  }).catch((err) => {
    alert("Not getting data 666AAA", err);
  });
}

export const deleteBullData = (bullId) => {
  return clinicordercountDB.bullList.delete(bullId);
}

export const updateBullData = (key, changes) => {
  return clinicordercountDB.bullList.update(key, changes).then((data) => {
  }).catch((err) => {
  })
}

export const putproducerdetails = (data) => {
  return clinicordercountDB.producerdetails.put(data).then((data) => {
    return Promise.resolve({data: {}});
  }).catch((err) => {
    alert("Not getting data 666", err)
  })
}

export const deleteproducerdetails = (producerId) => {
  return clinicordercountDB.producerdetails.delete(producerId).then((data) => {
    return clinicordercountDB.producerbasicwithbullinfo.delete(producerId)
    // return Promise.resolve({data: {}});
  }).catch((err) => {
    alert("Not getting data 666", err)
  });
}

export const getVetProducerdetails = (producerId) => {
  return clinicordercountDB.clinicProducers.get(producerId, (data) => {
    return data ? Promise.resolve({data: {producerDetail: data}}) : Promise.reject("Data Not Found");
});
}

export const getproducerdetails = (producerId) => {
  return clinicordercountDB.producerdetails.get(producerId, (data) => {
      return data ? Promise.resolve({data: {producerDetail: data}}) : Promise.reject("Data Not Found");
  });
}

export const updateproducerdetails = (key, changes) => {
  return clinicordercountDB.producerdetails.update(key, changes).then((data) => {
  }).catch((err) => {
  })
}

export const putEvaluationList = (data, bullId, isPublished, authToken, callApi=true) => {
  const evaList = {
    bull_id: `${bullId}_${isPublished}`,
    isPublished: isPublished,
    value: data
  };
  clinicordercountDB.evaluationList.put({...evaList}).then(() => {
  }).catch((err) => {
    alert("Not getting data 22222", err)
  });
  if(callApi){
    data.forEach(async(evalData) => {
      const evaletails = await singleEvaluation(authToken, evalData.evaluation_id);
      evaletails.data.evaluation && putEvaluationData(evaletails.data.evaluation);
    })
  }
}

export const getEvaluationList = (evalutionId) => {
  return clinicordercountDB.evaluationList.get(evalutionId, (data) => {
    return (data && data.value) ? Promise.resolve({evaluationList: data.value}) : Promise.reject("Data Not Found");
  });
}

export const deleteEvaluationListData = (bullId, isPublished, evalutionId, withUpdateField=false) => {
  return getEvaluationList(`${bullId}_${isPublished}`).then((evalData) => {
    evalData.evaluationList = evalData.evaluationList.filter((evaluation) => evaluation.evaluation_id !== evalutionId);
    putEvaluationList(evalData.evaluationList, bullId, isPublished, "", false);
    getBullData(bullId).then((bullData) => {
      if(isPublished){
        bullData['published_cnt'] -= 1;
      } else {
        bullData['unpublished_cnt'] -= 1;
      }
      if(withUpdateField){
        bullData['published_cnt'] += 1;
      }
      updateBullData(bullId, bullData);
    });
    if(!withUpdateField){
      return deleteEvaluationData(evalutionId).then(() => {
        return Promise.resolve({status: 200});
      });
    }
    return Promise.resolve({status: 200});
  })
  // return clinicordercountDB.evaluationList.delete(evalutionId);
}

export const putEvaluationData = (data) => {
  clinicordercountDB.singleEvaluationData.put({...data}).then(() => {
  }).catch((err) => {
    alert("Not getting data 11111", err)
  })
}

export const createEvaluationData = (data, bullId, isPublished) => {
  return clinicordercountDB.singleEvaluationData.put({...data}).then(() => {
    return getEvaluationList(`${bullId}_${isPublished}`).then((evalData) => {
      evalData.evaluationList = [...evalData.evaluationList, data];
      putEvaluationList(evalData.evaluationList, bullId, isPublished, "", false);
      return getBullData(bullId).then((bullData) => {
        if(isPublished){
          bullData['published_cnt'] += 1;
        } else {
          bullData['unpublished_cnt'] += 1;
        }
        updateBullData(bullId, bullData);
        return Promise.resolve({statu: 200, statusMessage: "Evaluation has been created"});
      });
    }).catch((err) => {
      if(err === "Data Not Found"){
        putEvaluationList([data], bullId, isPublished, "", false);
        return getBullData(bullId).then((bullData) => {
          if(isPublished){
            bullData['published_cnt'] += 1;
          } else {
            bullData['unpublished_cnt'] += 1;
          }
          updateBullData(bullId, bullData);
          return Promise.resolve({statu: 200, statusMessage: "Evaluation has been created"});
        });
      }
    })
  }).catch((err) => {
    alert("Not getting data 11111", err)
  })
}

export const getEvaluationData = (evalutionId) => {
  return clinicordercountDB.singleEvaluationData.get(evalutionId, (data) => {
    return data ? Promise.resolve({data: {evaluation: data}}) : Promise.reject("Data Not Found");
  });
}

export const getEvaluationDataWithBullId1 = (bullId) => {
  return clinicordercountDB.singleEvaluationData.where("bull_id").equals(bullId).toArray();
}

export const updateEvaluationData = (evalutionId, changes, bullId, isPublished) => {
  return clinicordercountDB.singleEvaluationData.update(evalutionId, changes).then((data) => {
    return getEvaluationList(`${bullId}_${isPublished}`).then((evalData) => {
      const index = evalData.evaluationList.findIndex((evalDetails) => evalDetails.evaluation_id === evalutionId);
      if(index >= 0){
        evalData.evaluationList[index] = changes;
      } else {
        evalData.evaluationList.push(changes);
      }
      putEvaluationList(evalData.evaluationList, bullId, isPublished, '', false);
      if(isPublished){
        return deleteEvaluationListData(bullId, 0, evalutionId, true).then(() => {
          return Promise.resolve({statu: 200, statusMessage: "Evaluation has been Updated"});
        });
      }
    }).catch((err) => {
      if(err === "Data Not Found"){
        putEvaluationList([changes], bullId, isPublished, "", false);
        if(isPublished){
          return deleteEvaluationListData(bullId, 0, evalutionId, true).then(() => {
            return Promise.resolve({statu: 200, statusMessage: "Evaluation has been Updated"});
          });
        }
      }
    })
  }).catch((err) => {
  })
}

export const deleteEvaluationData = (evalutionId) => {
  return clinicordercountDB.singleEvaluationData.delete(evalutionId);
}

export const putBullTreatmentList = (bullId, value) => {
  const data = {
    id: bullId,
    value: value
  }

  clinicordercountDB.bullTreatments.put({...data}).then(() => {
  }).catch((err) => {
    // alert("Not getting data 111", err)
  })
}

export const updateBullTreatmentList = (bullId, value) => {
  const data = {
    id: bullId,
    value: value
  }

  clinicordercountDB.bullTreatments.update(bullId, data).then((data) => {
  }).catch((err) => {
  })
}

// export const getBullTreatmentList = (bullId) => {
//   return clinicordercountDB.bullTreatments.get(bullId, (data) => {
//     return (data && data.value) ? Promise.resolve({data: {treatments: data.value}}) : Promise.resolve({data: {treatments: []}});
//   });
// }
