import React from 'react';
import {Button} from '@material-ui/core';
import {useContext, useState, useEffect} from 'react';
import ReactToPdf from 'react-to-pdf';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import {AuthContext} from '../../App'
import {getSingleOrder} from '../../store/actions/order'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const SingleOrder = (props) => {
  const ref = React.createRef();
  const { state } = React.useContext(AuthContext)
  const [order, setOrder] = useState()
  const [totalTax, setTotalTax] = useState(0)
  const [taxDetails, setTaxDetails] = useState(0)
  const [paid, setPaid] = useState()
  useEffect(() => {
    getSingleOrder(state.token, props.match.params.id).then(({data}) => {
      console.log('data single order', JSON.parse(data.paymentsDetail[0].paypal_data));
      setPaid(JSON.parse(data.paymentsDetail[0].paypal_data).paid)
      let taxes = JSON.parse(data.paymentsDetail[0].tax_details)
      setTaxDetails(taxes)
      let total_tax = 0;
      for(let tax in taxes) {
        total_tax += (taxes[tax])
      }
      setOrder(data.paymentsDetail[0])
      setTotalTax(total_tax)

    }).catch(err => console.log(err))
  }, [])


  function url_head() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Orders</h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Link to="/Adminorders">Orders</Link>
              </li>
              <li className="breadcrumb-item active">
                <span>{props.match.params.id}</span>
              </li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  let pdf_height = 1, pdf_width = 1;
  if(document.getElementById('single_order')){
    pdf_height = document.getElementById('single_order').offsetHeight
    pdf_width = document.getElementById('single_order').offsetWidth
  }
  return (
    <>
      {url_head()}
      <div className="container" >

        <div className="card" id="single_order"  ref={ref}>
          <div className="card-header">
            
              <ReactToPdf targetRef={ref} filename={`${props.match.params.id}_${order && order.date_purchased.split(" ").join("_")}.pdf`} options={{
                orientation: 'landscape',
                unit: 'in',
                format: [pdf_height, pdf_width]
              }}>
                {({toPdf}) => (
                  <Button color="primary" onClick={toPdf} style={{float:'right'}} variant="contained">print</Button>
                )}
              </ReactToPdf>

              <strong>Name:  </strong>
                  {order && order.clinic_name}
                  <br/>
                  <strong>Email:  </strong>
                  {order && order.email}
                  <br/>
                  
                  
                  <strong>Address:    </strong>
                  {order && order.address},&nbsp;
                  {order && order.city},&nbsp;
                  {order && order.province}, &nbsp;
                  {order && order.postal_code}&nbsp;
                  <br/>
                  <strong>Invoice:   </strong>
                  {order && order.date_purchased}

            <span className="float-right"> <strong>Status:</strong> {paid == true ? 'Paid' : 'Cancelled'}</span>
          </div>
          <div style={{textAlign: 'center', paddingTop:'10px'}}><img src="/images/logo.png"></img></div>
          <div className="card-body">
            <div className="table-responsive-sm">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="center">#</th>
                    <th>Item</th>
                    <th className="right">Total Cost</th>
                    <th className="right">Taxes</th>

                    <th className="center">Qty</th>
                    <th className="right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="center">{order && order.order_id}</td>
                    <td className="left strong">Evaluation Forms</td>
                    <td className="right">${order && (order.amount - totalTax).toFixed(2)}</td>
                    <td className="right">${totalTax.toFixed(2)}</td>

                    <td className="center">{order && order.form_count}</td>
                    <td className="right">${order && order.amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-5">
              </div>
              <div className="col-lg-4 col-sm-5 ml-auto">
                <table className="table table-clear">
                  <tbody>
                   
                    <tr>
                      <td className="left">
                        <strong>GST </strong>
                      </td>
                      <td className="right">
                        {taxDetails.gst && taxDetails.gst.toFixed(2) }
                        </td>
                    </tr>
                    <tr>
                      <td className="left">
                        <strong>PST </strong>
                      </td>
                      <td className="right">
                        {taxDetails.pst && taxDetails.pst.toFixed(2) }
                        </td>
                    </tr>
                    <tr>
                      <td className="left">
                        <strong>HST </strong>
                      </td>
                      <td className="right">
                        {taxDetails.hst && taxDetails.hst.toFixed(2) }
                        </td>
                    </tr>
                    <tr>
                      <td className="left">
                        <strong>QST </strong>
                      </td>
                      <td className="right">
                        {taxDetails.qst && taxDetails.qst.toFixed(2) }
                        </td>
                    </tr>
                    <tr>
                      <td className="left">
                        <strong>Total</strong>
                      </td>
                      <td className="right">
                        <strong>${order && order.amount}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
                <div style={{color:'blue', textAlign:'left', paddingLeft:'10px'}}>GST# 898314174 and PST # 7266786</div>
        </div>
      </div>
    </>
  );
}

export default SingleOrder;