import React from 'react';
import AddEditProducer from '../../components/producer/AddProducer';
import '../../App.scss';

const AddProducer = (props) => {
  return (
    <AddEditProducer {...props} />
  );
}

export default AddProducer;

