import React, {useEffect, useRef, useState, useContext } from 'react';
import {makeStyles, Grid, Button,TextField,RadioGroup,FormControlLabel,Radio} from '@material-ui/core';
import {Link} from 'react-router-dom'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import {useHistory, useLocation } from 'react-router-dom'
import { getBreeds } from '../../store/actions/report'
import { createBull } from '../../store/actions/bull'
import MenuItem from '@material-ui/core/MenuItem';
import { AuthContext,OrderContext } from "../../App"
import { getclinicorder} from '../../store/actions/clinic'
import {withSnackbar} from 'notistack'
import moment from 'moment'
import {getBull} from '../../store/actions/bull';
import { getbreedlist, getBullDetails, getClinicordercount, addBullDetails } from '../../indexedDB';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: '100%',
  },
}));

const AddBull = (props) => {
  const location = useLocation();
  let producer = location.state.producer
  const formRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  let {state} = useContext(AuthContext)
  const [breeds, setBreeds] = useState('');
  const [breedName, setBreedName] = useState('')
  const [breed, setBreed] = useState('');
  // const [other_breed, setOtherBreed] = useState('');
  const [orderCount, setOrderCount] = useState(OrderContext.order_count)
  const [birthday, setBirthday] = useState('');
  const [age_year, setAgeYear] = useState(0);
  const [age_month, setAgeMonth] = useState(0);
  const [dateError, setDateError] = useState(false)
  const [breedError, setbreedError] = useState(false)
  const [bull, setBull] = useState({
    producer_id: location.state.producerId,
    name: '',
    tag_type: '',
    brand: '',
    rfid_tag: '',
    breed_id: '',
    breed_other: '',
    birth_date: '',
    breed:''
  })
  const resetBullValues = () => {
    history.goBack()
  }

  const saveBullDetails = () => {
    bull.birth_date = birthday
    if(bull.breed == undefined){
      setbreedError(true)
      return
    }
    setbreedError(false)

    if(bull.birth_date == ""){
      setDateError(true)
    return
    }
    setDateError(false)
    bull.birth_date = +new Date(birthday) / 1000;
    bull.breed_id = breeds.find(item=>item.breed_id===bull.breed).breed_id;
    bull.breed=breeds.find(item=>item.breed_id===bull.breed).breed_name;
    bull.bull_id = (+new Date()).toString();
    bull.published_cnt = 0;
    bull.unpublished_cnt = 0;
    addBullDetails(bull.bull_id, bull, state.user.userId).then((data) => {
      props.enqueueSnackbar('New bull added',{variant:"success"});
      props.history.goBack()
      // props.history.push(`clinic/producer/${location.state.producerId}`)

    }).catch(err => {
      console.log('err', err)
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    })
  }
  useEffect(() => {
    getClinicordercount().then(({data}) => {
      setOrderCount(data.count)
    }).catch(err => {
      console.log(err)
    })
  },[]);
  const addEvaluation = () => {
    bull.birth_date = +new Date(birthday) / 1000;
    if(bull.breed == undefined){
      setbreedError(true)
      return
    }
    setbreedError(false)
    if(bull.birth_date == ""){
      setDateError(true)
    return
    }
    setDateError(false)
    bull.breed_id = breeds.find(item=>item.breed_id===bull.breed).breed_id;
    bull.bull_id = (+new Date()).toString();
    bull.published_cnt = 0;
    bull.unpublished_cnt = 0;
    addBullDetails(bull.bull_id, bull, state.user.userId).then(({data}) => {
      props.enqueueSnackbar('New bull added',{variant:"success"});
      getBullDetails(data.bullId).then(({data}) =>{
        history.push('/offline/clinic/add-new-evaluation', {
          clinicName: '',
          clinic_id: location.state.clinic_id,
          producerName: producer.name,
          clinicId: location.state.clinic_id,
          producerId: producer.ID,
          bullId: data.bull_details.bull_id,
          tag: data.bull_details.rfid_tag,
          name: data.bull_details.name,
          brandinput: data.bull_details.brand,
          birthDate: new Date(data.bull_details.birth_date*1000).toISOString().slice(0,10),
          breed: data.bull_details.breed_id,
          breedothers: data.bull_details.breed_other,
        });
      })

    }).catch(err => {
      console.log('err', err)
      props.enqueueSnackbar('Something went wrong',{variant:"warning"});
    })
  }

  const calulateAgeFromMonth = (event) => {
    setAgeMonth(event.target.value)
    let months = event.target.value?event.target.value:0;
    let total_months = age_year*12 + parseInt(months);
    var startdate = moment().subtract(total_months, "months").format("YYYY-MM-DD");
    setBirthday(startdate)
  }
  const calcluateAgeFromYear = (event) =>{
    setAgeYear(event.target.value)
    let age = event.target.value? event.target.value: 0
    let total_months = age*12 + parseInt(age_month);
    var startdate = moment().subtract(total_months, "months").format("YYYY-MM-DD");
    setBirthday(startdate)
  }

  const onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name == 'birthday') {
      if(value == ""){
        setDateError(true)
      return
      }
      setDateError(false)
      calculateAge(event)
    }
    if(name == 'breed') {
      if(value == ""){
        setbreedError(true)
        setBreed(value)
      return
      }
      setbreedError(false)
      setBreed(value)
      setBreedName(breeds.find(item=>item.breed_id===value).breed_name)
    }
    setBull(prevForm => ({
      ...prevForm,
      [name]:value
    }))
  }


  useEffect(() => {
       // Custom rule to validate the date format YYYY-MM-DD
       ValidatorForm.addValidationRule('isValidBirthDate', (value) => {
           const regex = /^\d{4}-\d{2}-\d{2}$/;

           if(regex.test(value)!==true) {
             setDateError(true);
             return;
           }
            setDateError(false);

            // Parse the date parts to integers
          const [year, month, day] = value.split('-').map(Number);

           // Check if the date parts form a valid date
           const date_comp = new Date(year, month - 1, day);
           if (
             date_comp.getFullYear() === year &&
             date_comp.getMonth() === month - 1 &&
             date_comp.getDate() === day
           ) {
             setDateError(false);
             return true;
           }else{
             setDateError(true);

             return;
           }


       });
   }, []);

  const handleChange = ( event) => {
    const { value } = event.target;
    console.log('Controlled Change', value);
     let formattedDate = '';


    // Ensure that only digits are considered
    const numericValue = value.replace(/\D/g, '');
    console.log(numericValue)
    console.log(numericValue.length)
    // Format the date string
    if(numericValue.length <= 4){
      formattedDate = value;
    }else if(numericValue.length <=6 ){
       formattedDate = numericValue.slice(0, 4)+ '-' + numericValue.slice(4, 6);
    }else if(numericValue.length <=8){
       formattedDate = numericValue.slice(0, 4)+ '-' + numericValue.slice(4, 6) + '-' + numericValue.slice(6, 8);
    }

    console.log(formattedDate)

    if(new Date(formattedDate).getTime() > new Date().getTime()){
      setDateError(true)
      return
    }
    setDateError(false)


    setBirthday(formattedDate)
    let date = new Date(formattedDate)
    let ageDifMs = Date.now() - date.getTime();
    let ageDate = new Date(ageDifMs)
    setAgeMonth(ageDate.getMonth())
    setAgeYear(Math.abs(ageDate.getUTCFullYear() - 1970))

  };

  const calculateAge = (event) => {
    if(new Date(event.target.value).getTime() > new Date().getTime()){
      setDateError(true)
      return
    }
    setDateError(false)
    setBirthday(event.target.value)
    let date = new Date(event.target.value)
    let ageDifMs = Date.now() - date.getTime();
    let ageDate = new Date(ageDifMs)
    setAgeMonth(ageDate.getMonth())
    setAgeYear(Math.abs(ageDate.getUTCFullYear() - 1970))
  }
  useEffect(() => {
    getbreedlist().then(({data}) => {
      setBreeds(data.breedList)
    }).catch(err => {
      console.log("err", err)
    })
  }, [])
  console.log("location",location)
  function url_head() {
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Add Bull </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/offline">
                  {state.onLine && <img className="profile-logout" className="img-xs rounded-circle" src="/images/nav-account-icon.png" alt="Profile" />}
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/offline/clinic/producer/${location.state.producerId}`}>{producer.name}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Add Bull</li>
            </ol>
          </nav>
          <br />
        </div>
      </div>
    )
  }
  return (
    <>
      {url_head()}
      <OrderContext.Consumer>
        {
          ({order_count, set_order_count}) => {
            set_order_count(orderCount)
            return (
              <div className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded add-edit-producer">
                <div className="MuiToolbar-root MuiToolbar-regular mb-4" style={{padding:15, }}>
                  <span className="MuiTypography-root MuiTypography-h5 mb-0" style={{color:'white', fontSize:'25px'}}><strong>Creating New Bull</strong></span>
                </div>
                  <ValidatorForm ref={formRef}  noValidate autoComplete="off">
                    <div className={classes.root}>
                      <Grid container spacing={1}>
                        <Grid item xs sm={9} style={{marginLeft:10}}>
                          <h4 className="mt-3" style={{color:'#000', marginTop:'10px'}}>Method of identification</h4>
                          <RadioGroup row name="tag_type" value={bull.tag_type} onChange={(event) => onChangeHandler(event)} style={{marginLeft:10}}>
                            <FormControlLabel value="earTag" control={<Radio />} label="Ear Tag" />
                            <FormControlLabel value="tattoo" control={<Radio />} label="Tattoo" />
                            <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs sm={3}>
                          <TextField
                            fullWidth
                            value={bull.brand}
                            type="text"
                            name="brand"
                            id="brandinput"
                            variant="outlined"
                            label="Bull ID(no special characters please)"
                            onChange={(event) => onChangeHandler(event)}
                          />
                        </Grid>
                      </Grid>
                      <hr />
                      <Grid container spacing={1}>
                        <Grid item xs sm={3}>
                          <TextField
                            fullWidth
                            type="text"
                            value={bull.rfid_tag}
                            name="rfid_tag"
                            id="rfid-tag"
                            variant="outlined"
                            label="RFID Tag"
                            onChange={(event) => onChangeHandler(event)}
                          />
                        </Grid>
                        <Grid item xs sm={3}>
                          <TextField
                            fullWidth
                            type="text"
                            name="name"
                            value={bull.name}
                            id="name"
                            variant="outlined"
                            label="Name"
                            onChange={(event) => onChangeHandler(event)}
                          />
                        </Grid>
                        <Grid item xs sm={3}>
                        <div className="selecterwrapbull">
                          <TextValidator name="breed"  required id="select-breed" select label="Select Breed" variant="outlined" value={breed} onChange={(e)=>onChangeHandler(e)}
                                validators={['required', 'maxStringLength:100']} errorMessages={['this field is required', 'the max length is 100']} >
                                  <MenuItem value="">None</MenuItem>
                                  {breeds?breeds.map((breed) => (
                                    <MenuItem key={breed.breed_id} value={breed.breed_id}>
                                      {breed.breed_name}
                                    </MenuItem>
                                  )):''}
                              </TextValidator>
                              {breedError?<span style={{color:'red', marginLeft:'10px'}}>Choose a breed</span>:null}
                              </div>
                        </Grid>
                        {

                        breedName == 'Other' ?
                        (<Grid item xs sm={3}>
                          <TextField
                            fullWidth
                            type="text"
                            onChange={(e)=>onChangeHandler(e)}
                            name="breed_other"
                            id="breed_other"
                            variant="outlined"
                            label="Other Breed Name"
                          />
                        </Grid> ): null
                        }
                      </Grid>
                      <Grid container spacing={1}>

                        <Grid item xs sm={3}>
                        <TextValidator  required  id="date" label="Date of Birth"  variant="outlined" placeholder="YYYY-MM-DD"
                        type="text" value={birthday} InputLabelProps={{  shrink: true,}} onChange={handleChange}
                        validators={['required','isValidBirthDate', 'maxStringLength:10']} errorMessages={['this field is required','', 'the max length is 10']}/>
                          {dateError?<span style={{color:'red', marginLeft:'10px'}}>Date must be in YYYY-MM-DD format</span>:null}
                        </Grid>
                      <span className="OrWrap">OR</span>
                        <Grid item xs sm={3}>
                          <TextValidator id="age-years" label="Age: Years" variant="outlined" value={age_year} onChange={(e)=>calcluateAgeFromYear(e)}/>
                        </Grid>
                        <Grid item xs sm={3}>
                          <TextValidator id="age-months" label="Age: Months" variant="outlined" value={age_month} onChange={(e)=>calulateAgeFromMonth(e)}/>
                        </Grid>

                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                        <Button variant="contained" disabled={dateError} color="primary" className={classes.margin} onClick={()=>saveBullDetails()}>Save</Button>
                        {/* <Button variant="contained" className="MuiButton-containedSecondary" onClick={() => addEvaluation()}>Add Evaluation</Button> */}
                        <Button style={{marginLeft:10}} color="primary" variant="contained" onClick={resetBullValues}>Cancel</Button>
                        </Grid>
                      </Grid>
                    </div>
                  </ValidatorForm>
                </div>
            )
          }
        }
      </OrderContext.Consumer>

    </>
  );
}

export default withSnackbar(AddBull);
