import {LOGIN, CHECKTOKEN, LOGOUT, OFFLINE, ONLINE} from './type'
import axios from '../axios'
import {history} from './../../routers/history'
import _ from 'lodash'
import key from './key'
import { putuserData, getuserData } from '../../indexedDB';
export const loginAdmin = (user, vm, dis_patch) =>{
  axios.post('/login', user, {
    headers:{
      "x-api-key": key,
    }
  }).then(({data}) =>{
    // console.log("vm", vm, data)
    vm.enqueueSnackbar('Admin Login Successfully',{variant:"success"});
    dis_patch({
      payload:data,
      type:LOGIN
    })
    
  }).catch(err => {
    vm.enqueueSnackbar('Something went wrong',{variant:"warning"});
    // console.log("err", err)
  })
}

const _comparePreviousUser = (fromAPI, fromindexedDB) => {
  return (fromindexedDB && fromindexedDB.data &&
          fromAPI.clinicId === fromindexedDB.data.clinicId &&
          fromAPI.email === fromindexedDB.data.email &&
          ((fromAPI.isClinic && fromindexedDB.data.isClinic) || 
            (fromAPI.isVet && fromindexedDB.data.isVet)));
}

const _makeUserLogin = (data, hasOfflineData, dis_patch) => {
  dis_patch({
    payload:{...data, hasOfflineData},
    type:LOGIN
  })
  putuserData(data.userDetail)
}

export const loginClinic = (user, vm, dis_patch) =>{
  axios.post('/loginclinic', user, {
    headers:{
      "x-api-key": key,
    }
  }).then(({data}) =>{
    getuserData().then((userData) => {
      if(_comparePreviousUser(data.userDetail, userData)){
        _makeUserLogin(data, true, dis_patch);
      } else {
        _makeUserLogin(data, false, dis_patch);
      }
      
    }).catch((err) => {
      if(err === 'Data Not Found'){
        _makeUserLogin(data, false, dis_patch);
      }
    })
  }).catch(err => {
    // console.log('erro', err.response)
    if(err.response.status == 409) {
      vm.enqueueSnackbar(`${err.response.data.message}`,{variant:"warning"});
    } else {
      vm.enqueueSnackbar('Incorrect Email/Password',{variant:"warning"});
    }
  })
}

export const sendOffline = (data, dis_patch) => {
  dis_patch(
    {
      payload:data,
      type:OFFLINE
    }
  )
}

export const sendOnline = (data, dis_patch) => {
  dis_patch(
    {
      payload:data,
      type:ONLINE
    }
  )
}

export const loginVet = (user, vm, dis_patch) =>{
  axios.post('/loginvet', user, {
    headers:{
      "x-api-key": key,
    }
  }).then(({data}) =>{
    getuserData().then((userData) => {
      if(_comparePreviousUser(data.userDetail, userData)){
        _makeUserLogin(data, true, dis_patch);
      } else {
        _makeUserLogin(data, false, dis_patch);
      }
      
    }).catch((err) => {
      if(err === 'Data Not Found'){
        _makeUserLogin(data, false, dis_patch);
      }
    })
    // dis_patch({
    //   payload:data,
    //   type:LOGIN
    // })
    // putuserData(data.userDetail);
  }).catch(err => {
    // console.log('erro', err.response)
    if(err.response.status == 409) {
      vm.enqueueSnackbar(`${err.response.data.message}`,{variant:"warning"});
    } else {
      vm.enqueueSnackbar('Incorrect Email/Password',{variant:"warning"});
    }
  })
}
export const clinicChangePassword = async (user, token) =>{
  return await axios.post('clinic/changepassword', user, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const vetChangePassword = async (user, token) =>{
  return await axios.post('vet/changepassword', user, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}
export const checkToken = async (token) =>{
  return await axios.get('checktoken', {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

export const otpVerification = async (data, token) =>{
  return await axios.post('asdas', data, {
    headers:{
      "x-api-key": key,
      "Authorization": token
    }
  })
}

