import React,  {useContext} from 'react';
import Table from '@material-ui/core/Table';
import {TableBody} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {useHistory} from 'react-router-dom'
import {IconButton} from '@material-ui/core'
import DeleteProducer from '../../components/producer/DeleteProducer';
import {AuthContext} from '../../App'
import {deleteProducer} from '../../store/actions/producer'
import {useSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import MaterialTable from 'material-table';
import { deleteclinicProducers } from '../../indexedDB';

export default function ProducerItems(props) {
  const { state: authState } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false)
  let history = useHistory()
  const [producer, setProducer] = React.useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [prefixURL, setPrefixURL] = React.useState('');
  let {state} = useContext(AuthContext) 
  const [producers, setProducers] = React.useState([])
  let color, backgroundColor,bordercolor;
  if(props.isPublished){
    backgroundColor="#def5db"
    color="#6eb542"
    bordercolor="#def5db"
  }else{
    backgroundColor="#fae0c9"
    color="#f68c31"
    bordercolor="#fae0c9"
  }
  React.useEffect(() => {
    if(!state.isOnline){
      setPrefixURL("/offline");
    }
    setProducers(props.producers)
  }, [props.producers]);

  const handleDialog = (producer) => {
    setProducer(producer)
    handleProducerDialog(true)
  }

  const handleProducerDialog = (value) => {
    setOpen(value)
  }

  const deleteSubmit = () => {
    producer.producer_id = producer.producer_id || producer.ID
    if(prefixURL){
      deleteclinicProducers(producer.producer_id).then((data) => {
        enqueueSnackbar(`Producer with name ${producer.name} deleted successfully`,{variant:'success'});
        setOpen(false)
        const newProducers = producers.filter((item) => item.producer_id !== producer.producer_id);
        setProducers(newProducers);
      }).catch(err => {
        console.log('err', err)
        enqueueSnackbar('something wrong while deleting bull',{variant:'warning'});
      });
      return true;
    }
    deleteProducer(state.token, producer.producer_id).then((data) => {
      enqueueSnackbar(`Producer with name ${producer.name} deleted successfully`,{variant:'success'});
      setOpen(false)
      const newProducers = producers.filter((item) => item.producer_id !== producer.producer_id);
      setProducers(newProducers);
    }).catch(err => {
      console.log('err', err)
      enqueueSnackbar('something wrong while deleting bull',{variant:'warning'});
    })
  }
  // console.log("producers",producers)
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{width:110}}>Name</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Location</TableCell>
              <TableCell align="left" style={{minWidth:100}}>City</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Province</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Postal Code</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Phone</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Email</TableCell>
              <TableCell align="left" style={{minWidth:100}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {producers && producers.map((producer, i) => {
              producer.producer_id = producer.producer_id || producer.ID;
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  <TableCell align="left" style={{minWidth:100}}>
                    <Link to={`${prefixURL}/clinic/producer/${producer.producer_id}`}>{producer && producer.name}</Link>
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.address}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.city}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.province}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.postal_code}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.phone}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    {producer && producer.email}
                  </TableCell>
                  <TableCell align="left" style={{minWidth:100}}>
                    <IconButton onClick={() => {history.push(`${prefixURL}/clinic/producer/${producer.producer_id}`)}} color="default" aria-label="upload picture" component="span">
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDialog(producer)} aria-label="upload picture" component="span">
                      <DeleteOutlineIcon />
                    </IconButton> 
                    {/* <IconButton color="primary" aria-label="upload picture" component="span">
                      <PictureAsPdfIcon />
                    </IconButton> */}

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* { authState.isClinic ? (<MaterialTable
      title = "Producers"
      columns={[

        { title: 'Name', field: 'name'},
        { title: 'Location', field: 'address'},
        { title: 'City', field: 'city' },
        { title: 'Province', field: 'province' },
        { title: 'Postal Code', field: 'postal_code' },
        { title: 'Phone', field: 'phone' },
        { title: 'Email', field: 'email' }, 
        { title: 'Actions', field: 'date_purchased' },

      ]}
      data = {producers}
      options = {{
        search : true
      }}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Edit Clinic',
          onClick : () => {history.push(`/clinic/producer/${producer.producer_id}`)}
          // onClick: (event, rowData) => {
          //   const found = data.find(e=>e.clinicId===rowData.clinicId);
          //   // console.log(found);
          //   setOpenEditClinic(true);
          //   setClinicName(found.clinic_name);
          //   setClinicAddr(found.clinic_address);
          //   setClinicCity(found.clinic_city);
          //   setClinicProvince(found.clinic_provinceName);
          //   setClinicZip(found.clinic_zip);
          //   setClinicPhone(found.clinic_phone);
          //   setClinicFax(found.clinic_fax);
          //   setClinicEmail(found.clinic_email);
          //   setClinicUsername(found.username);
          //   setClinicTProducers(found.total_producer);
          //   setClinicId(found.clinicId);
          // }
        // },
        // {
        //   icon: 'delete',
        //   tooltip: 'Delete Clinic',
        //   onClick: (event, rowData) => {
        //     const found = data.find(e=>e.clinicId===rowData.clinicId);
        //     setOpenConfirmClinic(true);
        //     setClinicId(found.clinicId);
        //   }
        // },
        // {
        //   icon: 'search',
        //   tooltip: 'Clinic Details',
        //   onClick: (event, rowData)=>{
        //     const found = data.find(e=>e.clinicId===rowData.clinicId);
        //     // console.log(found);
        //     history.push('/clinic/admin-clinic-list-details',
        //      { id: found.clinicId,
        //       username: found.username,
        //       name: found.clinic_name,
        //       addr: found.clinic_address,
        //       city: found.clinic_city,
        //       province: found.clinic_provinceName,
        //       zip: found.clinic_zip,
        //       phone: found.clinic_phone,
        //       fax: found.clinic_fax,
        //       email: found.clinic_email,
        //       tproducers: found.total_producer,
        //       provinceList: province_list
        //      })
        //   }
        // },
      //   }
      // ]}
    


      // />):(false)} */}

      <DeleteProducer 
        handleProducerDialog={handleProducerDialog}
        open={open}
        deleteSubmit={deleteSubmit}
        producer={producer}
      />
    </>
  );
}
